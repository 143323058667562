import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { fetchTariffs } from "../../../services/subscriptionService/subscriptionService";
import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Divider,
  CloseButton,
  ModalBody,
  ModalFooter,
  RadioGroup,
  RadioOption,
  TariffLink,
  Description,
} from "./ChangeTariffModal.styles";
import Button from "../../Buttons/Button";
import closeIcon from "../../../assets/icons/closeIcon.svg";
import { Tariff } from "../../../pages/SubscriptionPage/SubscriptionPage.types";
import { ChangeTariffModalProps } from "./ChangeTariffModal.types";

import selectedIcon from "../../../assets/icons/selectedIcon.svg";
import unselectedIcon from "../../../assets/icons/unselectedIcon.svg";

const ChangeTariffModal: React.FC<ChangeTariffModalProps> = ({
  isOpen,
  currentTariffName,
  onConfirm,
  onCancel,
}) => {
  const [tariffs, setTariffs] = useState<Tariff[]>([]);
  const [selectedTariffId, setSelectedTariffId] = useState<number | null>(null);

  useEffect(() => {
    if (isOpen) {
      fetchTariffs()
        .then((data) => {
          setTariffs(data);
          const currentTariff = data.find((tariff) => tariff.name === currentTariffName);
          setSelectedTariffId(currentTariff ? currentTariff.id : null);
        })
        .catch((error) => {
          console.error("Ошибка загрузки тарифов:", error);
        });
    }
  }, [isOpen, currentTariffName]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <ModalOverlay>
      <ModalContent>
        <ModalHeader>
          <h2>Подтверждение</h2>
          <CloseButton onClick={onCancel}>
            <img src={closeIcon} alt="close" />
          </CloseButton>
        </ModalHeader>
        <Divider />
        <ModalBody>
          <Description>
            Выберите тариф, на который хотите изменить тариф {currentTariffName}
          </Description>
          <RadioGroup>
            {tariffs.map((tariff) => (
              <RadioOption key={tariff.id}>
                <label>
                  <input
                    type="radio"
                    name="tariff"
                    value={tariff.id}
                    checked={selectedTariffId === tariff.id}
                    onChange={() => setSelectedTariffId(tariff.id)}
                  />
                  <img
                    src={selectedTariffId === tariff.id ? selectedIcon : unselectedIcon}
                    alt={selectedTariffId === tariff.id ? "Выбрано" : "Не выбрано"}
                  />
                  {tariff.name}
                </label>
              </RadioOption>
            ))}
          </RadioGroup>
          <TariffLink href="/profile">Узнать подробнее про тарифы</TariffLink>
        </ModalBody>
        <ModalFooter>
          <Button variant="flatNotFilled" onClick={onCancel}>
            Отмена
          </Button>
          <Button
            variant="flatFilled"
            onClick={() => selectedTariffId && onConfirm(selectedTariffId)}
            disabled={!selectedTariffId}
            width="175px"
          >
            Изменить
          </Button>
        </ModalFooter>
      </ModalContent>
    </ModalOverlay>,
    document.body
  );
};

export default ChangeTariffModal;

