import React from "react";
import { BreadcrumbLinkProps } from "./BreadcrumbLink.types";
import { BreadcrumbLinkStyled  } from "./BreadcrumbLink.styles";


const BreadcrumbLink: React.FC<BreadcrumbLinkProps> = ({ href = "/leads", text = "Лиды" }) => {
  return <BreadcrumbLinkStyled  href={href}>{text}</BreadcrumbLinkStyled>;
};

export default BreadcrumbLink;
