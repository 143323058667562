import styled from "styled-components";

export const SubscriptionContainer = styled.div`
  display: flex;
  height: calc(100% - 24px);
  margin: 12px;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  margin-left: 0;
  background: white;
  border-radius: 16px;
  box-shadow: 0px 9px 14.5px rgba(0, 0, 0, 0.06),
    0px 0px 6px rgba(31, 10, 53, 0.05);
  padding: 32px 25px;
  box-sizing: border-box;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 700;
  color: rgb(31, 10, 53);
`;

export const ButtonWrapper = styled.div``;

export const BaseButton = styled.div`
  font-size: 18px;
  font-weight: 700;
  background: none;
  border: none;
  cursor: pointer;
  transition: all 0.5s ease;

  &:hover {
    transform: translateY(-1px);
  }
`;

export const ChargeTariffButton = styled(BaseButton)`
  color: rgb(114, 82, 220);
`;
export const CancelSubscriptionButton = styled(BaseButton)`
  color: rgb(242, 40, 98);
`;

export const AutoRenewalSubscriptionButton = styled(BaseButton)`
  display: flex;
  justify-content: end;
  gap: 20px;
  color: rgb(143, 132, 154);
  font-weight: 500;
`;



export const SubscriptionDetails = styled.div`
  box-sizing: border-box;
  border: 1px solid rgba(31, 10, 53, 0.1);
  border-radius: 16px;
  box-shadow: 0px 0px 0px 6px rgba(31, 10, 53, 0.05);
  margin: 40px 0px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 34px;
  padding-right: 34px;
`;

export const SubscriptionLabel = styled.div`
  font-size: 18px;
  color: rgb(31, 10, 53);
  font-weight: 600;
  grid-column: 1; /* Первый столбец */
`;

export const SubscriptionValue = styled.div`
  font-size: 18px;
  color: rgb(31, 10, 53);
  font-weight: 500;
  grid-column: 2; /* Второй столбец */
`;

export const SubscriptionAction = styled.div`
  grid-column: 3; /* Третий столбец */
  text-align: right;
`;

export const SubscriptionRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 3fr;
  margin-bottom: 16px;
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 20px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-start;
`;


export const EditIcon = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
`;


export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;