import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import {
  NotePageContainer,
  ContentWrapper,
  TextArea,
  SaveButton,
  Toolbar,
  ToolbarButton,
  BreadcrumbsContainer,
  BreadcrumbItem,
} from "./NotePage.styles";
import Sidebar from "../../components/Sidebar";
import delIcon from "../../assets/icons/delIcon.svg";
import clearIcon from "../../assets/icons/clearIcon.svg";
import { ConfirmModal } from "../../components/Modals";
import BreadcrumbLink from "../../components/BreadcrumbLink/BreadcrumbLink";
import { NotePageProps } from "./NotePage.types";

const NotePage: React.FC<NotePageProps> = ({
  breadcrumbHref = "/leads",
  breadcrumbText = "Лиды",
  fetchData,
  updateData, 
}) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const toast = React.useRef<Toast>(null);

  const [note, setNote] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const loadNote = async () => {
      setLoading(true);
      try {
        const data = await fetchData(Number(id));
        setNote(data.note || "");
      } catch (error) {
        console.error("Ошибка при загрузке данных:", error);
        toast.current?.show({
          severity: "error",
          summary: "Ошибка",
          detail: "Не удалось загрузить данные.",
          life: 3000,
        });
      } finally {
        setLoading(false);
      }
    };

    loadNote();
  }, [fetchData, id]);

  const handleSave = async () => {
    try {
      await updateData(Number(id), note);
      toast.current?.show({
        severity: "success",
        summary: "Успешно",
        detail: "Заметка успешно обновлена.",
        life: 3000,
      });
      navigate(-1); // Возврат на предыдущую страницу
    } catch (error) {
      console.error("Ошибка при сохранении заметки:", error);
      toast.current?.show({
        severity: "error",
        summary: "Ошибка",
        detail: "Не удалось обновить заметку.",
        life: 3000,
      });
    }
  };

  const handleClearText = () => {
    setNote("");
    toast.current?.show({
      severity: "info",
      summary: "Текст очищен",
      detail: "Текстовое поле было очищено.",
      life: 2000,
    });
  };

  const handleDeleteNote = async () => {
    try {
      await updateData(Number(id), "");
      setNote("");
      toast.current?.show({
        severity: "success",
        summary: "Заметка удалена",
        detail: "Заметка была успешно удалена.",
        life: 3000,
      });
      navigate(-1); // Возврат на предыдущую страницу
    } catch (error) {
      console.error("Ошибка при удалении заметки:", error);
      toast.current?.show({
        severity: "error",
        summary: "Ошибка",
        detail: "Не удалось удалить заметку.",
        life: 3000,
      });
    }
  };

  const openConfirmModal = () => setIsConfirmModalOpen(true);
  const closeConfirmModal = () => setIsConfirmModalOpen(false);

  return (
    <NotePageContainer>
      <Toast ref={toast} />
      <Sidebar />
      <ContentWrapper>
        <BreadcrumbsContainer>
          <BreadcrumbItem>
            <BreadcrumbLink href={breadcrumbHref} text={breadcrumbText} />
          </BreadcrumbItem>
          <BreadcrumbItem>/ Заметки</BreadcrumbItem>
        </BreadcrumbsContainer>

        <h1>Заметки</h1>
        <Toolbar>
          <ToolbarButton onClick={handleClearText}>
            <img src={clearIcon} alt="Очистить текст" />
          </ToolbarButton>
          <ToolbarButton onClick={openConfirmModal}>
            <img src={delIcon} alt="Удалить заметку" />
          </ToolbarButton>
        </Toolbar>
        <TextArea
          id="note-textarea"
          value={note}
          onChange={(e) => setNote(e.target.value)}
          placeholder="Напишите текст"
        />
        <SaveButton onClick={handleSave}>Сохранить</SaveButton>
      </ContentWrapper>

      <ConfirmModal
        isOpen={isConfirmModalOpen}
        title="Удаление заметки"
        message="Вы уверены, что хотите удалить заметку? Это действие нельзя будет отменить."
        onConfirm={() => {
          handleDeleteNote();
          closeConfirmModal();
        }}
        onCancel={closeConfirmModal}
      />
    </NotePageContainer>
  );
};

export default NotePage;
