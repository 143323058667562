import React, { useState, useEffect, useRef } from "react";
import {
  StudentsPageContainer,
  ContentWrapper,
  HeaderContainer,
  Title,
  StatsWrapper,
  StatBlock,
  ButtonWrapper,
  TitleWrapper,
} from "./StudentsPage.styles";
import Sidebar from "../../components/Sidebar";
import Table from "../../components/Table";
import { createStudent, fetchStudents, updateAccess } from "../../services/studentService/studentService";
import { StudentPurchase } from "../../services/studentService/studentService.types";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import DropdownInCell from "../../components/Dropdowns/DropdownInCell/DropdownInCell";
import Button from "../../components/Buttons/Button";
import { CreateModal } from "../../components/Modals";
import { Course } from "../../services/leadService/leadService.types";
import { fetchCourses, fetchUserId } from "../../services/leadService/leadService";

const StudentsPage: React.FC = () => {
  const [students, setStudents] = useState<StudentPurchase[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(7);
  const [orderBy, setOrderBy] = useState<string>("");
  const [studentCount, setStudentCount] = useState<number>(0);
  const [courseCount, setCourseCount] = useState<number>(0);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [courses, setCourses] = useState<Course[]>([]);

  const toast = useRef<Toast>(null);
  const navigate = useNavigate();

  useEffect(() => {
    loadCourses();
  }, []);

  useEffect(() => {
    loadStudents();
  }, [currentPage, pageSize, orderBy]);


  const loadCourses = async () => {
      try {
        const userId = await fetchUserId();
        const coursesData = await fetchCourses({ userId });
  
        const normalizedCourses = coursesData.map((course) => ({
          id: Number(course.id),
          title: course.title,
        }));
  
        setCourses(normalizedCourses);
      } catch (err) {
        console.error("Ошибка при загрузке курсов:", err);
        toast.current?.show({
          severity: "error",
          summary: "Ошибка",
          detail: "Не удалось загрузить курсы",
          life: 3000,
        });
      }
    };

  const loadStudents = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetchStudents(currentPage, pageSize, orderBy);

      setStudents(response.results.results);
      setTotalPages(Math.ceil(response.count / pageSize));
      setStudentCount(response.results.aggregated_data.student_count);
      setCourseCount(response.results.aggregated_data.course_count);
    } catch (err) {
      console.error("Ошибка при загрузке учеников:", err);
      toast.current?.show({
        severity: "error",
        summary: "Ошибка",
        detail: "Ошибка загрузки учеников",
        life: 3000,
      });
      setError("Не удалось загрузить список учеников.");
    } finally {
      setLoading(false);
    }
  };

  const handleCourseAccessChange = async (paymentId: number, access: string) => {
    try {
      const updatedAccess = access === "Доступен";
      const response = await updateAccess(paymentId, updatedAccess);

      setStudents((prevStudents) =>
        prevStudents.map((student) =>
          student.payment_id === paymentId
            ? { ...student, course_access: response.course_access }
            : student
        )
      );

      toast.current?.show({
        severity: "success",
        summary: "Успешно",
        detail: "Доступ успешно обновлен",
        life: 3000,
      });
    } catch (error) {
      console.error("Ошибка при обновлении доступа:", error);
      toast.current?.show({
        severity: "error",
        summary: "Ошибка",
        detail: "Не удалось обновить доступ",
        life: 3000,
      });
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size: number) => {
    setPageSize(size);
    setCurrentPage(1);
  };

  const handleSort = (field: string) => {
    if (orderBy === field) {
      setOrderBy(`-${field}`);
    } else if (orderBy === `-${field}`) {
      setOrderBy("");
    } else {
      setOrderBy(field);
    }
  };

  const headers = [
    { label: "ФИО", key: "fullName", fieldName: "student__user_name" },
    { label: "Телеграм", key: "telegram", fieldName: "student__user_name" },
    { label: "Номер телефона", key: "phone", fieldName: "student__phone_number" },
    { label: "Почта", key: "email", fieldName: "student__email" },
    { label: "Название курса", key: "courseName", fieldName: "course__title" },
    { label: "Заметка", key: "note", fieldName: "note" },
    { label: "Доступ к курсу", key: "course_access", fieldName: "course_access" },
  ];

  const rows = students.map((student) => ({
    fullName: student.student.full_name,
    telegram: (
      <a href={`https://t.me/${student.student.user_name}`} target="_blank" rel="noopener noreferrer">
        @{student.student.user_name}
      </a>
    ),
    phone: student.student.phone_number,
    email: student.student.email,
    courseName: student.course.title,
    note: (
      <span
        style={{ color: "rgb(114, 82, 220)", cursor: "pointer" }}
        onClick={() => navigate(`/students/${student.payment_id}/note`)}
      >
        Смотреть
      </span>
    ),
    course_access: (
      <DropdownInCell
        options={[
          { label: "Доступен", value: "Доступен" },
          { label: "Не доступен", value: "Не доступен" },
        ]}
        value={student.course_access ? "Доступен" : "Не доступен"}
        onChange={(selectedValue) =>
          handleCourseAccessChange(student.payment_id, String(selectedValue))
        }
      />
    ),
  }));

  const rowAmountOptions = [
    { label: "7 учеников", value: 7 },
    { label: "15 учеников", value: 15 },
    { label: "20 учеников", value: 20 },
  ];

  const openCreateModal = () => {
    setIsCreateModalOpen(true);
  };

  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
  };

  const handleCreateStudent = async (formData: Record<string, string | File[] | null>) => {
    try {
      const payload = {
        surname: formData.surname as string,
        name: formData.name as string,
        patronymic: formData.patronymic as string | undefined,
        email: formData.email as string,
        telegramUsername: formData.telegramUsername as string | undefined,
        tg_id: formData.tg_id as string,
        phone: formData.phone as string,
        course: formData.course as string,
        note: formData.note as string | undefined,
      };
  
      const response = await createStudent(payload);
  
      toast.current?.show({
        severity: "success",
        summary: "Успешно",
        detail: response.message,
        life: 3000,
      });
  
      closeCreateModal();
      loadStudents(); // Обновляем список студентов после успешного добавления
    } catch (error: any) {
      const errorMessage =
      error.response?.data?.detail || "Произошла ошибка при создании студента.";
      console.error("Ошибка при создании ученика:", errorMessage);
      toast.current?.show({
        severity: "error",
        summary: "Ошибка",
        detail: errorMessage,
        life: 3000,
      });
    }
  };
  

  return (
    <StudentsPageContainer>
      <Toast ref={toast} />
      <Sidebar />
      <ContentWrapper>
        <HeaderContainer>
          <TitleWrapper>
            <Title>Список учеников</Title>
            <ButtonWrapper>
              <Button
                onClick={openCreateModal}
                type="button"
                variant="active"
                width="190px"
                height="50px"
              >
                Добавить ученика
              </Button>
            </ButtonWrapper>
          </TitleWrapper>
          <StatsWrapper>
            <StatBlock>
              Всего учеников: <span>{studentCount}</span>
            </StatBlock>
            <StatBlock>
              Купили курс: <span>{courseCount}</span>
            </StatBlock>
          </StatsWrapper>

        </HeaderContainer>
        <Table
          headers={headers}
          rows={rows}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          pageSize={pageSize}
          onPageSizeChange={handlePageSizeChange}
          onSort={handleSort}
          rowAmountOptions={rowAmountOptions}
          minHeight="230px"
          loading={loading}
        />
        {isCreateModalOpen && (
          <CreateModal
            title="Добавление ученика"
            fields={[
              { name: "surname", type: "text", placeholder: "Фамилия", required: true },
              { name: "name", type: "text", placeholder: "Имя", required: true },
              { name: "patronymic", type: "text", placeholder: "Отчество", required: false },
              { name: "email", type: "email", placeholder: "Почта", required: true },
              { name: "telegramUsername", type: "text", placeholder: "Имя в телеграм", required: false },
              { name: "tg_id", type: "text", placeholder: "Телеграм ID", required: true },
              { name: "phone", type: "text", placeholder: "Номер телефона", required: true },
              {
                name: "course",
                type: "selectWithInput",
                placeholder: "Курс",
                options: courses.map((course) => ({
                  label: course.title,
                  value: String(course.id),
                })), // Используем данные о курсах
                required: true,
              },
              { name: "note", type: "textarea", placeholder: "Заметки", required: false },
            ]}
            onSubmit={handleCreateStudent}
            submitButtonText="Сохранить"
            onCancel={closeCreateModal}
            hasDragAndDrop={false}
          />
        )}

      </ContentWrapper>
    </StudentsPageContainer>
  );
};

export default StudentsPage;
