import styled from "styled-components";
import checkMarkIcon from "../../assets/icons/checkMarkIcon.svg";

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-radius: 24px;
  box-shadow: 4px 4px 14px 0px rgba(202, 202, 202, 0.25),
    -4px -4px 14px 0px rgba(202, 202, 202, 0.25);
  background: rgb(255, 255, 255);
  width: 202px;
  height: 256px;
  padding: 20px;
  padding-bottom: 12px;
  z-index: 10;
`;

export const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;  

export const OptionItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;

  input {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  span {
    font-size: 16px;
    color: #333;
    margin-left: 8px;
  }

  input:checked + label::before {
    content: "";
    display: inline-block;
    width: 28px;
    height: 28px;
    border: 2px solid rgb(114, 82, 220);
    border-radius: 8px;
    background: rgb(114, 82, 220);
    background-image: url(${checkMarkIcon}); // Используем иконку
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12px 12px; // Размер иконки
  }

  input + label::before {
    content: "";
    display: inline-block;
    width: 28px;
    height: 28px;
    border: 1px solid rgb(185, 185, 185);
    border-radius: 8px;
    background-color: white;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  margin-top: 12px;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: -22px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: rgba(31, 10, 53, 0.1);
  }
`;

export const SaveButton = styled.button`
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 600;

  border: none;
  border-radius: 12px;
  background: rgb(114, 82, 220);
  padding: 14px 28px 14px 28px;
  cursor: pointer;
  width: 162px;
  height: 40px;

  &:hover {
    background: #5a0cad;
  }
`;

export const ResetButton = styled.button`
  background: none;
  color: rgb(143, 132, 154);
  font-size: 16px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  padding: 8px 12px;
  text-decoration: none;
`;
