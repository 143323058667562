import axiosInstance from "../axiosConfig";
import { Course, LeadData, Role } from "./leadService.types";


export const createLead = async (data: LeadData): Promise<{ id: number }> => {
  const url = "courses/leads/";
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch (error) {
    console.error("Ошибка при создании лида:", error);
    throw error;
  }
};

export const getLeads = async (
  page: number = 1,
  pageSize: number = 7,
  ordering: string = "",
  startDate?: string,
  endDate?: string,
  roles?: string // Теперь это массив
): Promise<{ results: LeadData[]; count: number }> => {
  const url = "courses/leads/";
  
  const params: Record<string, string | number | string[]> = {
    page,
    page_size: pageSize,
    ordering,
  };

  if (startDate && endDate){ 
    params.start_date = startDate;
    params.end_date = endDate;
  }
  console.log(roles);
  if (roles?.length) params.roles = roles; // Передаем массив ролей, если есть

  try {
    const response = await axiosInstance.get<{ results: LeadData[]; count: number }>(url, { params });
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении лидов:", error);
    throw error;
  }
};



export const fetchRoles = async (botId: string): Promise<Role[]> => {
  const url = "courses/roles/";
  try {
    const response = await axiosInstance.get(url, {
      params: { bot_id: botId }, // Передаём botId как query-параметр
    });
    return response.data.map((role: Role) => ({
      id: role.id,
      name: role.name,
    }));
  } catch (error) {
    console.error("Ошибка при получении ролей:", error);
    throw error;
  }
};

export const fetchLeadRoles = async (): Promise<Role[]> => {
  const url = "courses/roles/"; 
  try {
    const response = await axiosInstance.get<{ id: number; name: string }[]>(url);
    return response.data.map((role) => ({
      id: role.id,
      name: role.name,
    }));
  } catch (error) {
    console.error("Ошибка при получении списка ролей:", error);
    throw error;
  }
};

export const fetchUserId = async (): Promise<number> => {
  try {
    const response = await axiosInstance.get("courses/get_user_id/"); // Указываем только endpoint
    return response.data.user_id; // Возвращаем `user_id` из ответа
  } catch (error) {
    console.error("Ошибка при получении user_id:", error);
    throw error;
  }
};

export const fetchCourses = async (
  identifier: { botId?: string; userId?: number }
): Promise<Course[]> => {
  const url = "courses/courses_list/";

  try {
    const params: Record<string, string | number> = {};
    if (identifier.botId) {
      params.bot_id = identifier.botId; // Передаём bot_id
    } else if (identifier.userId) {
      params.user_id = identifier.userId; // Передаём user_id
    } else {
      throw new Error("Either botId or userId must be provided");
    }

    const response = await axiosInstance.get(url, { params });
    return response.data.map((course: Course) => ({
      id: course.id,
      title: course.title,
    }));
  } catch (error) {
    console.error("Ошибка при получении курсов:", error);
    throw error;
  }
};

export const selectCourseForLead = async (leadId: number, selectedCourseId: number | null): Promise<void> => {
  const url = `courses/select_course_for_lead/${leadId}/`;
  try {
    const response = await axiosInstance.post(url, {
      selected_course: selectedCourseId,
    });
    console.log("Курс успешно выбран:", response.data);
  } catch (error) {
    console.error("Ошибка при выборе курса:", error);
    throw error;
  }
};


export const deleteLead = async (leadId: number): Promise<void> => {
  const url = `courses/leads/${leadId}/`;
  try {
    await axiosInstance.delete(url);
    console.log(`Лид с ID ${leadId} успешно удалён.`);
  } catch (error) {
    console.error(`Ошибка при удалении лида с ID ${leadId}:`, error);
    throw error;
  }
};


export const updateLeadNote = async (leadId: number, note: string): Promise<void> => {
  const url = `courses/update_note/${leadId}/`;
  try {
    const response = await axiosInstance.post(url, { note });
    console.log(`Заметка для лида с ID ${leadId} успешно обновлена:`, response.data);
  } catch (error) {
    console.error(`Ошибка при обновлении заметки для лида с ID ${leadId}:`, error);
    throw error;
  }
};


export const fetchLeadById = async (leadId: number): Promise<LeadData> => {
  const url = `courses/leads/${leadId}/`;
  try {
    const response = await axiosInstance.get<LeadData>(url);
    return response.data;
  } catch (error) {
    console.error(`Ошибка при получении данных лида с ID ${leadId}:`, error);
    throw error;
  }
};