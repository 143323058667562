import styled from "styled-components";
import dropDownArrowIcon from "../../../assets/icons/dropDownArrowIcon.svg";

export const CellDropdownContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const CellDropdownInput = styled.input`
  color: rgb(31, 10, 53);
  font-size: 18px;
  font-weight: 400;
  border: none;
  outline: none;
  flex-grow: 1;

  &:focus {
    border: none;
    outline: none;
  }
`;

export const CellDropdownIcon = styled.span<{ isOpen: boolean }>`
  position: absolute;
  right: 135px;
  top: 50%;
  transform: translateY(-50%)
    rotate(${({ isOpen }) => (isOpen ? "0deg" : "180deg")});
  transition: transform 0.2s ease-in-out;
  width: 16px;
  height: 16px;
  background: url(${dropDownArrowIcon}) no-repeat center center;
  background-size: contain;
  cursor: pointer;
`;

export const CellDropdownList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border-radius: 24px;
  box-shadow: 4px 4px 14px 0px rgba(202, 202, 202, 0.25), -4px -4px 14px 0px rgba(202, 202, 202, 0.25);
  background: rgb(255, 255, 255);
  padding: 20px;
  z-index: 10000;
  width: auto;

  /* Ограничиваем высоту и включаем прокрутку */
  max-height: 150px; /* Ограничьте высоту списка */
  overflow-y: scroll; /* Включаем вертикальную прокрутку */

  /* Убираем скроллбар */
  &::-webkit-scrollbar {
    display: none; /* Скрываем скроллбар для браузеров с поддержкой Webkit */
  }
  -ms-overflow-style: none; /* Скрываем скроллбар для IE и Edge */
  scrollbar-width: none; /* Скрываем скроллбар для Firefox */
`;



export const CellDropdownItem = styled.li`
  cursor: pointer;
  list-style: none;
  color: rgb(39, 39, 39);
  font-family: font-family: ${({ theme }) => theme.fonts.mont};
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0%;
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);


  &:hover {
    color: rgb(114, 82, 220);
  }
`;
