import styled, { css } from "styled-components";

export const PageWrapper = styled.div`
  height: 100vh;
  background: radial-gradient(
      51% 51% at 50% 121%,
      rgba(131, 102, 228, 0.6),
      rgba(131, 102, 228, 0)
    ),
    rgb(247, 248, 248);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LidHeader = styled.h2`
  color: rgb(114, 82, 220);
  font-size: 29px;
  font-weight: 700;
  padding: 16px;
  margin: 0px;
  margin-top: 32px;
  margin-bottom: 39px;
  
`;

export const FormTitle = styled.h2`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  color: rgb(20, 20, 20);
  font-size: 24px;
  font-weight: 600;
  margin: 0px;
  margin-bottom: 24px;
`;




export const Input = styled.input<{ hasError?: boolean }>`
  padding: 12px 16px;
  width: 100%;
  font-size: 16px;
  border-radius: 10px;
  border: 1px solid rgba(31, 10, 53, 0.1);
  background: rgb(247, 248, 248);
  scrollbar-width: none;

  ${({ hasError }) =>
    hasError &&
    css`
      border: 1px solid rgb(242, 40, 98);
      background: rgba(114, 82, 220, 0.15);
    `}

  &:focus {
    border: 1px solid rgb(114, 82, 220);
    background: rgba(114, 82, 220, 0.15);
  }
`;

export const FormFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: auto;
  margin-bottom: 50px;
  padding-left: 12px;
  padding-right: 12px;
`;  