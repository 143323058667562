import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 429px;
  border-radius: 16px;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;

  h2 {
    color: rgb(31, 10, 53);
    font-size: 28px;
    font-weight: 700;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgb(225, 224, 229);
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  width: 24px;
  height: 24px;
  font-size: 24px;
  color: #ccc;
  cursor: pointer;

  &:hover {
    color: #000;
  }
`;

export const ModalBody = styled.div`
  padding: 20px 30px;

  p {
    color: rgb(31, 10, 53);
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;
  }
`;

export const Description = styled.p`
  color: rgb(31, 10, 53);
  opacity: 0.5;
  margin-bottom: 30px;
`;

export const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 16px;
  margin-top: 35px;
`;

export const RadioOption = styled.div`
  label {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    color: rgb(31, 10, 53);
    cursor: pointer;
    position: relative;
    padding-left: 40px; /* Отступ для иконки */
    margin-bottom: 10px;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    img {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 28px;
      height: 28px;
      transition: all 0.5s ease;

      &:hover {
        transform: translateY(-60%);
      }
    }
  }
`;

export const TariffLink = styled.a`
  display: inline-block;
  color: rgb(114, 82, 220);
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding: 16px 22px;
  padding-top: 0;

  button {
    flex: 1;
  }
`;
