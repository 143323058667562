import React, { useState, useRef, useEffect } from "react";
import {
  DropdownContainer,
  DropdownInput,
  DropdownList,
  DropdownItem,
  DropdownIcon,
} from "./DropdownWithObjects.styles";
import { DropdownOption, DropdownWithObjectsProps } from "./DropdownWithObjects.types";

const DropdownWithObjects: React.FC<DropdownWithObjectsProps> = ({
  options,
  placeholder = "Выберите значение",
  onChange,
  value = "",
}) => {
  const [inputValue, setInputValue] = useState<string>(
    options.find((option) => option.value === value)?.label || ""
  );
  const [filteredOptions, setFilteredOptions] = useState<DropdownOption[]>(options);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => setIsDropdownOpen((prev) => !prev);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const userInput = e.target.value;
    setInputValue(userInput);

    // Фильтруем список
    const filtered = options.filter((option) =>
      option.label.toLowerCase().includes(userInput.toLowerCase())
    );
    setFilteredOptions(filtered);
  };

  const handleOptionClick = (option: DropdownOption) => {
    setInputValue(option.label);
    onChange(option.value); // Передаём выбранное значение
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    // Закрытие dropdown при клике вне области
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Обновляем список при изменении `options`
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    // Устанавливаем label выбранного значения в поле ввода при изменении `value`
    const selectedOption = options.find((option) => option.value === value);
    setInputValue(selectedOption?.label || "");
  }, [value, options]);

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownInput
        type="text"
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}
        onClick={() => setIsDropdownOpen(true)}
      />
      <DropdownIcon isOpen={isDropdownOpen} onClick={toggleDropdown} />
      {isDropdownOpen && (
        <DropdownList>
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option) => (
              <DropdownItem key={option.value} onClick={() => handleOptionClick(option)}>
                {option.label}
              </DropdownItem>
            ))
          ) : (
            <DropdownItem>Нет совпадений</DropdownItem>
          )}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};

export default DropdownWithObjects;
