import { SubscriptionResponse, Tariff  } from "../../pages/SubscriptionPage/SubscriptionPage.types";
import axiosInstance from "../axiosConfig";

export const fetchSubscriptionByTariffAndUser = async (
  tariffId: number,
  userId: number
): Promise<SubscriptionResponse > => {
  const url = `/subscriptions/active-subscription/`;
  try {
    const response = await axiosInstance.get<SubscriptionResponse >(url, {
      params: {
        tariff_id: tariffId,
        user_id: userId,
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      `Ошибка при получении данных подписки для тарифа с ID ${tariffId} и пользователя с ID ${userId}:`,
      error
    );
    throw error;
  }
};

export const cancelSubscription = async (tariffId: number): Promise<{ message: string }> => {
  const url = `/subscriptions/cancel-subscription/`;
  try {
    const response = await axiosInstance.post<{ message: string }>(url, { tariff_id: tariffId });
    return response.data;
  } catch (error) {
    console.error(`Ошибка при отмене подписки для тарифа с ID ${tariffId}:`, error);
    throw error;
  }
};

export const changeTariff = async (
  oldTariffId: number,
  newTariffId: number
): Promise<{ message: string; new_tariff_name: string }> => {
  const url = `/subscriptions/change-tariff/`;
  try {
    const response = await axiosInstance.post<{ message: string; new_tariff_name: string }>(url, {
      old_tariff_id: oldTariffId,
      new_tariff_id: newTariffId,
    });
    return response.data;
  } catch (error) {
    console.error(
      `Ошибка при смене тарифа с ID ${oldTariffId} на ${newTariffId}:`,
      error
    );
    throw error;
  }
};


export const toggleAutoRenewal = async (
  tariffId: number
): Promise<{ message: string; auto_renewal: boolean }> => {
  const url = `/subscriptions/toggle-auto-renewal/`;
  try {
    const response = await axiosInstance.post<{ message: string; auto_renewal: boolean }>(url, {
      tariff_id: tariffId,
    });
    return response.data;
  } catch (error) {
    console.error(`Ошибка при отключении автопродления для тарифа с ID ${tariffId}:`, error);
    throw error;
  }
};

export const fetchTariffs = async (): Promise<Tariff[]> => {
  const url = `/subscriptions/tariffs/`;
  try {
    const response = await axiosInstance.get<Tariff[]>(url);
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении списка тарифов:", error);
    throw error;
  }
};

export const createPayment = async (tariffId: number): Promise<string> => {
  const url = `/subscriptions/create-payment/`;
  try {
    const response = await axiosInstance.post<{ payment_url: string }>(url, { tariff_id: tariffId });
    return response.data.payment_url;
  } catch (error) {
    console.error("Ошибка при создании платежа:", error);
    throw error;
  }
};
