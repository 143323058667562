import React, { useState, useEffect, useRef } from "react";
import { SettingsPageContainer, PageContent, FormsContainer, SectionHeader } from "./SettingsPage.styles";
import SettingsForm from "../../components/SettingsForm/SettingsForm";
import Sidebar from "../../components/Sidebar";
import { getBotConfig, getUserData, updateUserData, createBotConfig, updateBotConfig, restartBot, deleteBotConfig } from "../../services/settingsServise/settingsServise";
import Cookies from "js-cookie";

import SuccessModal from "../../components/Modals/SuccessModal/SuccessModal";
import { sendVerificationCode } from "../../services/authService/authService";
import { Toast } from "primereact/toast";
import { BotFormData, UserFormData } from "./SettingsPage.types";

const SettingsPage: React.FC = () => {
  const [userFormData, setUserFormData] = useState<UserFormData>({
    organization: "",
    firstName: "",
    lastName: "",
    email: "",
    verificationCode: "",
  });

  const [newUserFormData, setNewUserFormData] = useState<UserFormData>({
    organization: "",
    firstName: "",
    lastName: "",
    email: "",
    verificationCode: "",
  });

  const [botFormData, setBotFormData] = useState<BotFormData>({
    botToken: ""
  });

  const [botConfigId, setBotConfigId] = useState<number | null>(null);
  const [isVerificationCodeSent, setIsVerificationCodeSent] = useState(false);
  const [isUserDataUpdated, setIsUserDataUpdated] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isEmailChanged, setIsEmailChanged] = useState(false);
  const [submitButtonText, setSubmitButtonText] = useState("Сохранить");
  const [userFormErrors, setUserFormErrors] = useState<Record<string, string>>({});
  const [botFormErrors, setBotFormErrors] = useState<Record<string, string>>({});
  const [isLoading, setIsLoading] = useState(false);

  const toast = useRef<Toast>(null);

  const userId = Number(Cookies.get("user_id"));

  useEffect(() => {
    if (showModal) {
      const timer = setTimeout(() => {
        setShowModal(false);
      }, 60000); // Закрыть через 60 секунд
      return () => clearTimeout(timer);
    }
  }, [showModal]);

  useEffect(() => {
    if (!userId) {
      console.error("ID пользователя отсутствует");
      toast.current?.show({ severity: "error", summary: "Ошибка", detail: "ID пользователя отсутствует", life: 3000 });
      return;
    }

    const fetchUserData = async () => {
      try {
        const userData = await getUserData(userId);
        setUserFormData({
          organization: userData.organization_name,
          firstName: userData.first_name,
          lastName: userData.last_name,
          email: userData.email,
          verificationCode: "",
        });
        setNewUserFormData({
          organization: userData.organization_name,
          firstName: userData.first_name,
          lastName: userData.last_name,
          email: userData.email,
          verificationCode: "",
        });
      } catch (error) {
        console.error("Ошибка при загрузке данных пользователя:", error);
        toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Ошибка загрузки данных пользователя", life: 3000 });
      }
    };

    fetchUserData();
  }, [userId]);

  useEffect(() => {
    if (!userId) {
      console.error("ID пользователя отсутствует");
      toast.current?.show({ severity: "error", summary: "Ошибка", detail: "ID пользователя отсутствует", life: 3000 });
      return;
    }

    const fetchBotConfig = async () => {
      try {
        const botConfig = await getBotConfig(userId);
        if (botConfig.length > 0) {
          const botData = botConfig[0];
          setBotConfigId(botData.id);
          setBotFormData({
            botToken: botData.bot_token,
          });
        }
      } catch (error) {
        console.error("Ошибка при загрузке настроек бота:", error);
        toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Ошибка загрузки настроек бота", life: 3000 });
      }
    };

    fetchBotConfig();
  }, [userId]);

  useEffect(() => {
    if (isUserDataUpdated) {
      setUserFormData(newUserFormData);
      setIsUserDataUpdated(false);
    }
  }, [newUserFormData, isUserDataUpdated]);

  const handleUpdateSubmit = async (data: Record<string, string>) => {
    const emailChanged = newUserFormData.email !== userFormData.email;
  
    if (submitButtonText === "Отправить код") {
      try {
        await sendVerificationCode(newUserFormData.email, "emailChange");
        toast.current?.show({ severity: "success", summary: "Успешно", detail: "Код подтверждения отправлен", life: 3000 });
        setIsVerificationCodeSent(true);
        setSubmitButtonText("Сохранить");
      } catch (error: any) {
        if (error.response && error.response.data) {
          setUserFormErrors(error.response.data);
        } else {
          console.error("Ошибка при отправке кода подтверждения:", error);
          toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Ошибка отправки кода подтверждения", life: 3000 });
        }
      }
    } else {
      try {
        const updateData: Record<string, string> = {
          organization_name: newUserFormData.organization,
          first_name: newUserFormData.firstName,
          last_name: newUserFormData.lastName,
        };
  
        // Добавляем почту и код только если email изменился
        if (emailChanged) {
          updateData.email = newUserFormData.email;
          updateData.verification_code = data.verification_code;
        }
  
        await updateUserData(userId, updateData);
  
        setIsVerificationCodeSent(false);
        setIsUserDataUpdated(true);
        setShowModal(true);
        setUserFormData(newUserFormData);
        setUserFormErrors({});
        console.log("Данные пользователя успешно обновлены");
      } catch (error: any) {
        if (error.response && error.response.data) {
          setUserFormErrors(error.response.data);
        } else {
          console.error("Ошибка обновления данных пользователя:", error);
          toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Ошибка обновления данных пользователя", life: 3000 });
        }
      }
    }
  };
  

  const handleBotSettingsSubmit = async (data: Record<string, string>) => {
    setIsLoading(true); // Блокируем кнопки
    toast.current?.show({ severity: "info", summary: "Создание", detail: "Телеграмм бот создается. Пожалуйста, подождите", life: 6000 });
    try {
      if (botConfigId) {
        await updateBotConfig(botConfigId, userId, {
          bot_token: data.botToken,
        });
        setBotFormData({
          botToken: data.botToken,
        });
        console.log("Настройки бота успешно обновлены");
      } else {
        const responseData = await createBotConfig({
          user: userId,
          bot_token: data.botToken,
        });
        setBotConfigId(responseData.id);
        console.log("Настройки бота успешно созданы");
        setBotFormData({
          botToken: data.botToken,
        });
      }
      toast.current?.show({ severity: "success", summary: "Успешно", detail: "Бот успешно создан", life: 3000 });
    } catch (error: any) {
      if (error.response && error.response.data) {
        setBotFormErrors(error.response.data);
      } else {
        console.error("Ошибка сохранения настроек бота:", error);
        toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Ошибка сохранения настроек бота", life: 3000 });
      }
    } finally {
      setIsLoading(false); // Разблокируем кнопки
    }
  };
  

  const handleBotRestart = async () => {
    setIsLoading(true); // Блокируем кнопки
    toast.current?.show({ severity: "info", summary: "Перезапуск", detail: "Бот перезапускается. Пожалуйста, подождите", life: 6000 });
    try {
      if (botConfigId) {
        await restartBot(botConfigId);
        toast.current?.show({ severity: "success", summary: "Успешно", detail: "Бот успешно перезапущен", life: 3000 });
      }
    } catch (error: any) {
      console.error("Ошибка при перезапуске бота:", error);
      toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Ошибка при перезапуске бота", life: 3000 });
    } finally {
      setIsLoading(false); // Разблокируем кнопки
    }
  };
  

  const handleBotDelete = async () => {
    try {
      if (botConfigId) {
        await deleteBotConfig(botConfigId)
        setBotConfigId(null);
        toast.current?.show({ severity: "success", summary: "Успешно", detail: "Бот удален", life: 3000 });
      }
    }
    catch (error: any) {
        toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Ошибка при удалении бота", life: 3000 });
    }
  }

  const handleUserFormChange = (fieldName: string, value: string) => {
    if (fieldName === "email") {
      setSubmitButtonText("Отправить код");
    }
    setNewUserFormData(prevData => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const emailChanged = newUserFormData.email !== userFormData.email;

  return (
    <SettingsPageContainer>
      <Toast ref={toast} />
      <Sidebar />
      <PageContent>
        <SectionHeader>Настройки</SectionHeader>
        <FormsContainer>
          <SettingsForm
            title="Обновление данных"
            fields={[
              { name: "organization", type: "text", placeholder: "Организация", value: newUserFormData.organization, required: true },
              { name: "firstName", type: "text", placeholder: "Ваше имя", value: newUserFormData.firstName, required: true },
              { name: "lastName", type: "text", placeholder: "Ваша фамилия", value: newUserFormData.lastName, required: true },
              { name: "email", type: "email", placeholder: "Электронная почта", value: newUserFormData.email, required: true },
              ...(emailChanged && isVerificationCodeSent
                ? [{ name: "verification_code", type: "text", placeholder: "Введите код из письма", required: true }]
                : []),
            ]}
            onChange={handleUserFormChange}
            onSubmit={handleUpdateSubmit}
            submitButtonText={submitButtonText}
            externalErrors={userFormErrors}
          />
          <SettingsForm
            title="Настройки бота"
            fields={[
              { name: "botToken", type: "text", placeholder: "Токен бота", value: botFormData.botToken, required: true },
            ]}
            onSubmit={handleBotSettingsSubmit}
            onRestart={handleBotRestart}
            onDelete={handleBotDelete}
            submitButtonText="Сохранить"
            externalErrors={botFormErrors}
            additionalButtons={true}
            isButtonsDisabled={!botConfigId || isLoading}
          />
        </FormsContainer>
      </PageContent>
      {showModal && (
        <SuccessModal
          message="Изменения успешно сохранены"
          onClose={() => setShowModal(false)}
        />
      )}
    </SettingsPageContainer>
  );
};

export default SettingsPage;


