import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  html, body {
    font-family: ${({ theme }) => theme.fonts.primary};
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    scrollbar-width: none;
    height: 100%;
  }

  #root {
    scrollbar-width: none;
    height: 100%;
    overflow: hidden;
  }

.p-datepicker.p-component.custom-calendar-panel.p-datepicker-monthpicker.p-ripple-disabled {
  font-size: 10px;
  margin-left: 50px;
  width: 250px !important;
}

.p-datepicker.p-component.custom-calendar-panel.p-datepicker-monthpicker.p-ripple-disabled.p-connected-overlay-enter {
  font-size: 10px;
  margin-left: 50px;
  width: 250px !important;
}

.p-datepicker.p-component.custom-calendar-panel.p-datepicker-monthpicker.p-ripple-disabled.p-connected-overlay-enter-done {
  font-size: 10px;
  margin-left: 50px;
  width: 250px !important;
}

/* Делаем основной контейнер квадратным */
.p-datepicker {
  width: 250px; 
  height: 280px; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-left: 50px;
}
  .p-datepicker.p-datepicker-monthpicker {
  width: 250px;
  height: 170px;
    
  }

/* Уменьшаем размеры внутренней таблицы */
.p-datepicker-calendar {
  width: 100%;
  table-layout: fixed; 
}

/* Уменьшаем размеры ячеек */
.p-datepicker-calendar td,
.p-datepicker-calendar th {
  height: 5px; 
  width: 5px; 
  padding: 0;
  text-align: center;
  vertical-align: middle;
}

/* Уменьшаем расстояние между строками */
.p-datepicker-calendar tr {
  line-height: 1;
  margin: 0; 
}

/* Уменьшаем отступы внутри ячеек */
.p-datepicker-calendar td span {
  padding: 0;
  font-size: 10px; 
  line-height: 1.2;
}

/* Заголовок календаря */
.p-datepicker-header {
  padding: 4px 0;
  text-align: center;
  font-size: 14px;
}

/* Кнопки переключения */
.p-datepicker-header button {
  width: 24px;
  height: 24px;
  padding: 0;
}

/* Стрелки переключения */
.p-datepicker-prev-icon,
.p-datepicker-next-icon {
  width: 14px;
  height: 14px;
}

/* Дни недели (thead) */
.p-datepicker-calendar thead th span {
  font-size: 10px;
  line-height: 1;
  text-align: center;
}

.p-datepicker-title {
  margin-left: 30px;
  margin-right: 30px;
  gap: 40px;
  display: flex;
  justify-content: space-between;
}


`;
