import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import {
  CellDropdownContainer,
  CellDropdownInput,
  CellDropdownIcon,
  CellDropdownList,
  CellDropdownItem,
} from "./DropdownInCell.styles";
import { DropdownOption, DropdownInCellProps } from "./DropdownInCell.types";

const DropdownInCell: React.FC<DropdownInCellProps> = ({
  options,
  value = "",
  onChange,
}) => {
  const [inputValue, setInputValue] = useState<string>(
    value === "" ? "Выбрать" : options.find((option) => option.value === value)?.label || "Выбрать"
  );
  const [filteredOptions, setFilteredOptions] = useState<DropdownOption[]>(options);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const [dropdownPosition, setDropdownPosition] = useState<{
    top: number;
    left: number;
    width: number;
  }>({ top: 0, left: 0, width: 0 });

  const toggleDropdown = () => {
    if (isDropdownOpen && inputValue === "") {
      // Если пользователь не выбрал значение и закрыл список, возвращаем "Выбрать"
      const selectedOption = options.find((option) => option.value === value);
      setInputValue(selectedOption?.label || "Выбрать");
    }

    if (!isDropdownOpen) {
      const rect = dropdownRef.current?.getBoundingClientRect();
      if (rect) {
        setDropdownPosition({
          top: rect.bottom,
          left: rect.left,
          width: rect.width,
        });
      }

      // Очищаем поле, если в нём "Выбрать"
      if (inputValue === "Выбрать") {
        setInputValue("");
      }
    }

    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const userInput = e.target.value;
    setInputValue(userInput);

    const filtered = options.filter((option) =>
      option.label.toLowerCase().includes(userInput.toLowerCase())
    );
    setFilteredOptions(filtered);
  };

  const handleOptionClick = (option: DropdownOption) => {
    setInputValue(option.label);
    onChange(option.value);
    setIsDropdownOpen(false);
  };

  // Синхронизируем inputValue с value, переданным извне
  useEffect(() => {
    const selectedOption = options.find((option) => option.value === value);
    if (selectedOption) {
      setInputValue(selectedOption.label);
    }
  }, [value, options]);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  return (
    <>
      <CellDropdownContainer ref={dropdownRef}>
        <CellDropdownInput
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onClick={toggleDropdown}
        />
        <CellDropdownIcon isOpen={isDropdownOpen} onClick={toggleDropdown} />
      </CellDropdownContainer>
      {isDropdownOpen &&
        ReactDOM.createPortal(
          <CellDropdownList
            style={{
              position: "absolute",
              top: dropdownPosition.top,
              left: dropdownPosition.left,
              width: dropdownPosition.width,
            }}
          >
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <CellDropdownItem
                  key={option.value}
                  onClick={() => handleOptionClick(option)}
                >
                  {option.label}
                </CellDropdownItem>
              ))
            ) : (
              <CellDropdownItem>Нет совпадений</CellDropdownItem>
            )}
          </CellDropdownList>,
          document.body
        )}
    </>
  );
};

export default DropdownInCell;
