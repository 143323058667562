import React, { useRef } from "react";
import {
  TariffCardContainer,
  TariffTitle,
  TariffInfoContainer,
  TariffPrice,
  TariffExpiration,
  TariffDescription,
  TariffDescriptionContainer,
  TariffNonActiveDescription,
} from "./TariffCard.styles";
import Button from "../Buttons/Button";
import { TariffCardProps } from "./TariffCard.types";
import { formatPrice } from "../../utils/formatNumber";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";

const TariffCard: React.FC<TariffCardProps> = ({
  title,
  description,
  price,
  isActive,
  expirationDate,
  tariffId,
  onChangeTariff,
}) => {
  const toastRef = useRef<Toast>(null); 
  const navigate = useNavigate();

  const handleButtonClick = async () => {
    if (isActive) {
      navigate(`/subscriptions/${tariffId}`);
    } else {
      // Вызов функции изменения тарифа
      onChangeTariff(tariffId);
    }
  };

  const buttonVariant = isActive ? "pink" : "default";

  return (
    <>
      <Toast ref={toastRef} />
      <TariffCardContainer>
        <TariffInfoContainer>
          <TariffTitle>{title}</TariffTitle>
          <TariffPrice>{formatPrice(price)}</TariffPrice>
          <TariffExpiration>
            {expirationDate ? `действует до ${expirationDate}` : "Недоступно"}
          </TariffExpiration>
        </TariffInfoContainer>
        <TariffDescriptionContainer>
          <TariffDescription>{description.active}</TariffDescription>
          <TariffNonActiveDescription>
            {description.nonActive}
          </TariffNonActiveDescription>
        </TariffDescriptionContainer>
        <Button variant={buttonVariant} onClick={handleButtonClick}>
          {isActive ? "Управлять" : "Перейти"}
        </Button>
      </TariffCardContainer>
    </>
  );
};

export default TariffCard;
