import React, { useState, useEffect } from "react";
import { FormTitle, LidHeader, PageWrapper, FormFieldWrapper, Input, ButtonWrapper } from "./LidFormPage.styles";
import { FormData } from "./LidFormPage.types";
import DropdownWithObjects from "../../components/Dropdowns/DropdownWithObjects/DropdownWithObjects";
import Button from "../../components/Buttons/Button";
import SuccessModal from "../../components/Modals/SuccessModal/SuccessModal";
import { LeadData } from "../../services/leadService/leadService.types";
import { createLead, fetchCourses, fetchRoles } from "../../services/leadService/leadService";

const LidFormPage: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    surname: "",
    phone: "",
    email: "",
    role: "",
    course: "",
  });
  const [errors, setErrors] = useState<Record<string, string | null>>({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
  const [roles, setRoles] = useState<{ id: number; name: string }[]>([]);
  const [courses, setCourses] = useState<{ id: number; title: string }[]>([]);
  const [botId, setBotId] = useState<string | null>(null);
  const [userNameFromTelegram, setUserNameFromTelegram] = useState<string | null>(null);
  const [userTgId, setUserTgId] = useState<string | null>(null);
  const [defaultCourseId, setDefaultCourseId] = useState<string | null>(null); 
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false); // Состояние для модального окна

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const botIdFromTelegram = queryParams.get("bot_id");
    const userNameTelegram = queryParams.get("user_name");
    const courseIdFromUrl = queryParams.get("course_id");
    const userTGIdFromUrl = queryParams.get("user_id");

    if (botIdFromTelegram) {
      setBotId(botIdFromTelegram);
    } else {
      console.error("Bot ID not found in URL.");
      setIsLoading(false);
    }

    if (userTGIdFromUrl) {
      setUserTgId(userTGIdFromUrl);
    }

    if (courseIdFromUrl) {
      setDefaultCourseId(courseIdFromUrl);
    }

    if (userNameTelegram) {
      setUserNameFromTelegram(userNameTelegram);
    }
  }, []);

  useEffect(() => {
    const loadDropdownData = async () => {
      try {
        if (!botId) {
          console.error("Bot ID is not available. Cannot fetch data.");
          return;
        }

        const [fetchedRoles, fetchedCourses] = await Promise.all([
          fetchRoles(botId),
          fetchCourses({ botId }),
        ]);

        setRoles(fetchedRoles);
        setCourses(fetchedCourses);

        if (defaultCourseId) {
          const defaultCourse = fetchedCourses.find((course) => course.id.toString() === defaultCourseId);
          if (defaultCourse) {
            setFormData((prevFormData) => ({
              ...prevFormData,
              course: defaultCourse.id.toString(),
            }));
          }
        }
      } catch (error) {
        console.error("Ошибка при загрузке данных для выпадающих списков:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (botId) {
      loadDropdownData();
    }
  }, [botId, defaultCourseId]);

  const validateForm = () => {
    const allFieldsValid = Object.values(formData).every((value) => value.trim() !== "");
    setIsSubmitDisabled(!allFieldsValid);
  };

  const handleChange = (name: string, value: string) => {
    setFormData({ ...formData, [name]: value });
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
    validateForm();
  };

  const handleSubmit = async () => {
    const newErrors: Record<string, string | null> = {};

    if (!formData.name.trim()) newErrors.name = "Это поле обязательно";
    if (!formData.email.trim()) newErrors.email = "Это поле обязательно";
    if (!formData.phone.trim()) newErrors.phone = "Это поле обязательно";

    setErrors(newErrors);

    if (Object.values(newErrors).every((err) => !err)) {
      if (!botId) {
        console.error("Bot ID is not available. Cannot create lead.");
        return;
      }

      const leadData: LeadData = {
        name: formData.name,
        surname: formData.surname,
        phone: formData.phone,
        email: formData.email,
        role: formData.role,
        course: formData.course ? Number(formData.course) : null,
        telegram: userNameFromTelegram,
        bot_id: botId,
        tg_id: userTgId,
        note: "",
      };

      try {
        await createLead(leadData);
        setShowSuccessModal(true); // Показываем модальное окно
        setTimeout(() => {
          if (window.Telegram?.WebApp?.close) {
            window.Telegram.WebApp.close(); // Закрываем Web App через 2 секунды
          }
        }, 2000);
      } catch (error) {
        console.error("Ошибка при создании лида:", error);
      }
    }
  };

  if (isLoading) {
    return <div>Загрузка...</div>;
  }

  if (showSuccessModal) {
    return <SuccessModal message="Заявка успешно отправлена!" onClose={() => {}} />;
  }

  return (
    <PageWrapper>
      <LidHeader>LISRENT</LidHeader>
      <FormTitle>Заявка</FormTitle>
      <FormFieldWrapper>
        <Input
          type="text"
          placeholder={"Имя"}
          value={formData.name}
          onChange={(e) => handleChange("name", e.target.value)}
          hasError={false}
        />
        <Input
          type="text"
          placeholder={"Фамилия"}
          value={formData.surname}
          onChange={(e) => handleChange("surname", e.target.value)}
          hasError={false}
        />
        <Input
          type="text"
          placeholder={"Номер телефона"}
          value={formData.phone}
          onChange={(e) => handleChange("phone", e.target.value)}
          hasError={false}
        />
        <Input
          type="text"
          placeholder={"Почта"}
          value={formData.email}
          onChange={(e) => handleChange("email", e.target.value)}
          hasError={false}
        />
        <DropdownWithObjects
          options={courses.map((course) => ({ label: course.title, value: course.id.toString() }))}
          placeholder={"Курс"}
          value={formData.course}
          onChange={(value) => handleChange("course", value)}
        />
        <DropdownWithObjects
          options={roles.map((role) => ({ label: role.name, value: role.id.toString() }))}
          placeholder={"Роль"}
          value={formData.role}
          onChange={(value) => handleChange("role", value)}
        />
      </FormFieldWrapper>
      <ButtonWrapper>
        <Button
          type="button"
          disabled={isSubmitDisabled}
          width="100%"
          onClick={handleSubmit}
        >
          Отправить заявку
        </Button>
      </ButtonWrapper>
    </PageWrapper>
  );
};

export default LidFormPage;
