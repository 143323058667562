import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import "./i18n";
import { I18nextProvider } from "react-i18next";
import LoginPage from "./pages/LoginPage";
import { ThemeProvider, StyleSheetManager } from "styled-components";
import isPropValid from "@emotion/is-prop-valid";
import { GlobalStyles } from "./styles/globalStyles"; 
import { theme } from "./styles/theme"; 
import { NotFoundPage } from "./pages/NotFoundPage";
import RegistrationPage from "./pages/RegistrationPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";

import "primereact/resources/themes/lara-light-purple/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./locale-config";

import PrivateRoute from "./routes/PrivateRoute";
import ReferralHandler from "./handlers/ReferralHandler";

import CoursesPage from "./pages/CoursesPage";
import LessonsPage from "./pages/LessonsPage";
import StudentsPage from "./pages/StudentsPage";
import ReferralPage from "./pages/ReferralPage/ReferralPage";
import SettingsPage from "./pages/SettingsPage/SettingsPage";
import PaymentPage from "./pages/PaymentPage/PaymentPage";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import SupportRequestPage from "./pages/SupportRequestPage/SupportRequestPage";
import TemplatesPage from "./pages/TemplatesPage/TemplatesPage";
import SupportResponsePage from "./pages/SupportResponsePage";
import AssignmentsPage from "./pages/AssignmentsPage/AssignmentsPage";
import StatisticsPage from "./pages/StatisticsPage/StatisticsPage";
import AssignmentResponsesPage from "./pages/AssignmentResponsesPage/AssignmentResponsesPage";
import { AuthProvider } from "./context/AuthContext";
import AssignmentResponseDetailPage from "./pages/AssignmentResponseDetailPage/AssignmentResponseDetailPage";
import LidFormPage from "./pages/LidFormPage/LidFormPage";
import LeadsPage from "./pages/LeadsPage/LeadsPage";
import NotePage from "./pages/NotePage/NotePage";
import { fetchLeadById, updateLeadNote } from "./services/leadService/leadService";
import { fetchPurchaseById, updateNote } from "./services/studentService/studentService";
import SubscriptionPage from "./pages/SubscriptionPage/SubscriptionPage";

const App: React.FC = () => {
  return (
    <StyleSheetManager shouldForwardProp={isPropValid}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <AuthProvider>
        <Router>
          <ReferralHandler />
          <Routes>
            {/* Открытые маршруты */}
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<RegistrationPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route path="/lead-create" element={<LidFormPage />} />


            {/* Защищенные маршруты */}
            <Route element={<PrivateRoute />}>
              <Route path="/" element={<Navigate to="/courses" replace />} />
              <Route path="/main" element={<Navigate to="/courses" replace />} />
              <Route path="/courses" element={<CoursesPage viewMode="lessons" />} />
              <Route path="/courses/:courseId/lessons" element={<LessonsPage />} />
              <Route path="/settings" element={<SettingsPage />} />
              <Route path="/students" element={<StudentsPage />} />
              <Route path="/referals" element={<ReferralPage />} />
              <Route path="/homework" element={<AssignmentResponsesPage />} />
              <Route path="/assignment-responses/:id" element={<AssignmentResponseDetailPage />} />
              <Route path="/payment" element={<PaymentPage />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/support-requests" element={<SupportRequestPage />} />
              <Route path="/support-response/:id" element={<SupportResponsePage />} />
              <Route path="/courses/:courseId/lessons/:lessonId/assignments" element={<AssignmentsPage />} />
              <Route path="/documents" element={<TemplatesPage />} />
              <Route path="/statistics" element={<StatisticsPage />} />
              <Route path="/leads" element={<LeadsPage />} />
              <Route path="/leads/:id/note" element={<NotePage fetchData={fetchLeadById} updateData={updateLeadNote}/>} />
              <Route path="/students/:id/note" element={<NotePage fetchData={fetchPurchaseById} updateData={updateNote} breadcrumbHref="/students" breadcrumbText="Ученики" />} />
              <Route path="/subscriptions/:tariff_id" element={<SubscriptionPage />} />
            </Route>

            {/* Страница 404 */}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Router>
        </AuthProvider>
      </ThemeProvider>
    </StyleSheetManager>
  );
};

export default App;
