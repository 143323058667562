import React, { useEffect, useState, useRef } from "react";
import {
  ContentWrapper,
  HeaderContainer,
  Title,
  ButtonWrapper,
  SubscriptionContainer,
  SubscriptionDetails,
  SubscriptionRow,
  SubscriptionLabel,
  SubscriptionValue,
  ChargeTariffButton,
  CancelSubscriptionButton,
  AutoRenewalSubscriptionButton,
  SubscriptionAction,
  EditIcon,
  TitleContainer,
} from "./SubscriptionPage.styles";
import BreadcrumbLink from "../../components/BreadcrumbLink/BreadcrumbLink";
import Button from "../../components/Buttons/Button";
import Sidebar from "../../components/Sidebar";
import ConfirmModal from "../../components/Modals/ConfirmModal/ConfirmModal";
import ChangeTariffModal from "../../components/Modals/ChangeTariffModal/ChangeTariffModal";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router-dom";
import { fetchSubscriptionByTariffAndUser, cancelSubscription, toggleAutoRenewal, createPayment } from "../../services/subscriptionService/subscriptionService";
import { SubscriptionResponse } from "./SubscriptionPage.types";
import editIcon from "../../assets/icons/editIcon.svg";
import { Toast } from "primereact/toast";

const SubscriptionPage: React.FC = () => {
  const navigate = useNavigate();
  const { tariff_id } = useParams<{ tariff_id: string }>();
  const userId = Cookies.get("user_id");
  const [subscriptionData, setSubscriptionData] = useState<SubscriptionResponse | null>(null);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isAutoRenewalModalOpen, setIsAutoRenewalModalOpen] = useState(false);
  const [isChangeTariffModalOpen, setIsChangeTariffModalOpen] = useState(false);
  const [isFinalConfirmModalOpen, setIsFinalConfirmModalOpen] = useState(false);
  const [selectedTariffId, setSelectedTariffId] = useState<number | null>(null);
  const toast = useRef<Toast>(null);

  useEffect(() => {
    if (!tariff_id || !userId) {
      toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Необходимо указать тариф и пользователя." });
      navigate("/login");
      return;
    }

    fetchSubscriptionByTariffAndUser(Number(tariff_id), Number(userId))
      .then((data) => setSubscriptionData(data))
      .catch((error) => {
        console.error("Ошибка получения данных подписки:", error);
        toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Не удалось загрузить данные подписки." });
      });
  }, [tariff_id, userId, navigate]);

  const handleLogout = () => {
    Cookies.remove("access_token");
    Cookies.remove("refresh_token");
    Cookies.remove("user_id");
    localStorage.clear();
    navigate("/login");
    toast.current?.show({ severity: "success", summary: "Выход", detail: "Вы успешно вышли из системы." });
  };

  const handleCancelSubscription = () => {
    setIsCancelModalOpen(true);
  };

  const confirmCancelSubscription = async () => {
    setIsCancelModalOpen(false);
    if (tariff_id) {
      try {
        const response = await cancelSubscription(Number(tariff_id));
        toast.current?.show({ severity: "success", summary: "Успех", detail: response.message });
        setTimeout(() => navigate("/profile"), 3000);
      } catch (error) {
        console.error("Ошибка при отмене подписки:", error);
        toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Не удалось отменить подписку." });
      }
    }
  };

  const handleToggleAutoRenewal = () => {
    if (subscriptionData?.auto_renewal) {
      setIsAutoRenewalModalOpen(true);
    }
  };

  const confirmToggleAutoRenewal = async () => {
    setIsAutoRenewalModalOpen(false);
    if (tariff_id) {
      try {
        const response = await toggleAutoRenewal(Number(tariff_id));
        setSubscriptionData((prev) => (prev ? { ...prev, auto_renewal: response.auto_renewal } : null));
        toast.current?.show({ severity: "success", summary: "Успех", detail: response.message });
      } catch (error) {
        console.error("Ошибка при отключении автопродления:", error);
        toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Не удалось отключить автопродление." });
      }
    }
  };

  const handleChangeTariff = () => {
    setIsChangeTariffModalOpen(true);
  };

  const confirmChangeTariff = (newTariffId: number) => {
    setSelectedTariffId(newTariffId);
    setIsChangeTariffModalOpen(false);
    setIsFinalConfirmModalOpen(true);
  };

  const finalConfirmChangeTariff = async () => {
    setIsFinalConfirmModalOpen(false);
    if (selectedTariffId) {
      try {
        const paymentUrl = await createPayment(selectedTariffId); // Получение URL оплаты от ЮKassa
        toast.current?.show({ severity: "success", summary: "Успех", detail: "Перенаправление на оплату..." });
        setTimeout(() => {
          window.location.href = paymentUrl; // Редирект на ЮKassa
        }, 1000);
      } catch (error) {
        console.error("Ошибка при создании платежа:", error);
        toast.current?.show({ severity: "error", summary: "Ошибка", detail: "Не удалось создать платеж." });
      }
    }
  };

  if (!subscriptionData) {
    return (
      <SubscriptionContainer>
        <Toast ref={toast} />
        <Sidebar />
        <ContentWrapper>
          <HeaderContainer>
            <Title>Личный кабинет / Управление подпиской</Title>
          </HeaderContainer>
          <p>Загрузка данных подписки...</p>
        </ContentWrapper>
      </SubscriptionContainer>
    );
  }

  return (
    <SubscriptionContainer>
      <Toast ref={toast} />
      <Sidebar />
      <ContentWrapper>
        <HeaderContainer>
          <TitleContainer>
            <BreadcrumbLink href="/profile" text="Личный кабинет / Управление подпиской" />
            <Title>{subscriptionData.tariff_name}</Title>
          </TitleContainer>
          <ButtonWrapper>
            <Button variant="red" width="120px" height="50px" onClick={handleLogout}>
              Выход
            </Button>
          </ButtonWrapper>
        </HeaderContainer>
        <SubscriptionDetails>
          <SubscriptionRow>
            <SubscriptionLabel>Тариф</SubscriptionLabel>
            <SubscriptionValue>{subscriptionData.tariff_name}</SubscriptionValue>
          </SubscriptionRow>
          <SubscriptionRow>
            <SubscriptionLabel>Дата следующего списания</SubscriptionLabel>
            <SubscriptionValue>{new Date(subscriptionData.end_date).toLocaleDateString()}</SubscriptionValue>
            <SubscriptionAction>
              {subscriptionData.auto_renewal && (
                <AutoRenewalSubscriptionButton onClick={handleToggleAutoRenewal}>
                  Автопродление
                  <EditIcon src={editIcon} alt="Изменить" />
                </AutoRenewalSubscriptionButton>
              )}
            </SubscriptionAction>
          </SubscriptionRow>
          <SubscriptionRow>
            <SubscriptionLabel>Дата окончания подписки</SubscriptionLabel>
            <SubscriptionValue>{new Date(subscriptionData.end_date).toLocaleDateString()}</SubscriptionValue>
          </SubscriptionRow>
          <SubscriptionRow>
            <ChargeTariffButton onClick={handleChangeTariff}>Сменить тариф</ChargeTariffButton>
          </SubscriptionRow>
          <SubscriptionRow>
            <CancelSubscriptionButton onClick={handleCancelSubscription}>Отменить подписку</CancelSubscriptionButton>
          </SubscriptionRow>
        </SubscriptionDetails>

        <ConfirmModal
          isOpen={isCancelModalOpen}
          title="Отменить подписку"
          message="Вы уверены, что хотите отменить подписку?"
          onConfirm={confirmCancelSubscription}
          onCancel={() => setIsCancelModalOpen(false)}
        />

        <ConfirmModal
          isOpen={isAutoRenewalModalOpen}
          title="Отключить автопродление"
          message="Вы уверены, что хотите отключить автопродление?"
          onConfirm={confirmToggleAutoRenewal}
          onCancel={() => setIsAutoRenewalModalOpen(false)}
        />

        <ChangeTariffModal
          isOpen={isChangeTariffModalOpen}
          currentTariffName={subscriptionData.tariff_name}
          onConfirm={confirmChangeTariff}
          onCancel={() => setIsChangeTariffModalOpen(false)}
        />

        <ConfirmModal
          isOpen={isFinalConfirmModalOpen}
          title="Подтверждение"
          message="При смене тарифа подписка начинается заново"
          confirmButtonText="Оплатить"
          onConfirm={finalConfirmChangeTariff}
          onCancel={() => setIsFinalConfirmModalOpen(false)}
        />
      </ContentWrapper>
    </SubscriptionContainer>
  );
};

export default SubscriptionPage;
