import styled from "styled-components";

export const TariffCardContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  padding: 30px;
  padding-bottom: 22px;
  box-sizing: border-box;
  border: 1px solid rgba(31, 10, 53, 0.1);
  border-radius: 16px;
  height: 100%;
  width: 430px;

  box-shadow: 0px 9px 14.5px 0px rgba(0, 0, 0, 0.06),
    0px 0px 0px 6px rgba(31, 10, 53, 0.05);
  background: rgb(255, 255, 255);

  button {
    margin: 0;
  }
`;
export const TariffInfoContainer = styled.div`
  display: grid;
  grid-template-areas:
    "expiration expiration"
    "title price";
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto auto;
  gap: 8px;
  margin-bottom: 16px;
  text-align: center;
`;

export const TariffExpiration = styled.span`
  text-align: left;
  grid-area: expiration;
  color: rgb(185, 185, 185);
  font-size: 16px;
  font-weight: 600;
`;

export const TariffTitle = styled.h3`
  text-align: left;
  grid-area: title;
  color: rgb(31, 10, 53);
  font-size: 28px;
  font-weight: 600;
  margin: 0;
`;

export const TariffPrice = styled.div`
  text-align: center;
  align-self: end;
  grid-area: price;
  font-size: 18px;
  font-weight: bold;
  color: #ff4081;
`;


export const TariffDescriptionContainer = styled.div`
  margin-bottom: 20px;
  text-align: left;
  margin-bottom: auto;
  height: calc(100% - 123px);
  overflow-y: scroll; /* Прокрутка по вертикали */
  scrollbar-width: none; /* Скрытие скроллбара для Firefox */

  /* Скрытие скроллбара для Webkit-браузеров (Chrome, Safari) */
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const TariffDescription = styled.p`
  color: rgb(31, 10, 53);
  font-size: 16px;
  font-weight: 500;
   white-space: pre-wrap;
`;

export const TariffNonActiveDescription = styled.p`
  color: rgb(185, 185, 185);
  font-size: 16px;
  font-weight: 500;
   white-space: pre-wrap;
`;

