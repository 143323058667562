import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import { CalendarContainer } from "./Calendar.styles";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarProps } from "./Calendar.types";
import { registerLocale } from "react-datepicker";
import { ru } from "date-fns/locale/ru";
import { Locale } from "date-fns";

registerLocale("ru", ru as unknown as Locale);
const Calendar = forwardRef<any, CalendarProps>(({
  onDateChange,
  dateFormat = "dd.MM.yyyy",
  startDate,
  endDate,
  isRange = false,
}, ref) => {
  return (
    <CalendarContainer>
      <DatePicker
        ref={ref}
        inline // Показывает только календарь
        selected={startDate}
        onChange={onDateChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        dateFormat={dateFormat}
        locale="ru"
      />
    </CalendarContainer>
  );
});

export default Calendar;
