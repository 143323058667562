import styled, { css } from "styled-components";
import { Calendar } from "primereact/calendar";

export const SidebarContainer = styled.aside`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  padding: 8px;
  margin-right: 12px;

  box-shadow: 2px 6px 29px 0px rgba(0, 0, 0, 0.09);
  background: rgb(255, 255, 255);
  min-height: 100%;
`;

export const SidebarHeader = styled.h2`
  color: rgb(114, 82, 220);
  font-size: 29px;
  font-weight: 700;
  padding: 16px;
  margin: 0px;
`;

export const SidebarMenu = styled.nav`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin-top: 20px;

  overflow-y: auto;
  scrollbar-width: none;
  // scrollbar-width: thin;               
  scrollbar-color: rgba(114, 82, 220, 0.5) transparent;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(114, 82, 220, 0.5);
    border-radius: 4px;
  }

`;

export const SidebarGroup = styled.div`
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const SidebarTitle = styled.h3`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 14px;
  font-weight: 600;
  color: rgb(185, 185, 185);
  padding-left: 16px;
  margin: 0px;

`;

export const SidebarItem = styled.li`
  list-style: none;
  margin: 6px 0;
 

  a {
    text-decoration: none;
  }
`;

export const SidebarLink = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 12px;
  padding: 12px;

  span {
    font-size: 18px;
    font-weight: 500;
  }
  ${(props) =>
    props.isActive &&
    css`
      box-shadow:
        0px 7px 15.4px 0px rgba(121, 90, 223, 0.5),
        inset 0px -3px 0px 0px rgb(105, 71, 216);

      background: linear-gradient(
          180deg,
          rgba(189, 169, 255, 0.44),
          rgba(114, 82, 220, 0.5) 100%
        ),
        rgb(114, 82, 220);

      span {
        color: rgb(255, 255, 255);
      }
      img {
        filter: invert(1);
      }
    `}

  &:hover {
    box-shadow:
      0px 7px 15.4px 0px rgba(121, 90, 223, 0.5),
      inset 0px -3px 0px 0px rgb(105, 71, 216);

    background: linear-gradient(
        180deg,
        rgba(189, 169, 255, 0.44),
        rgba(114, 82, 220, 0.5) 100%
      ),
      rgb(114, 82, 220);

    span {
      color: rgb(255, 255, 255);
    }
    img {
      filter: invert(1);
    }
  }
`;

export const SidebarIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 12px;
`;

export const SidebarText = styled.span`
    font-size: 18px;
    font-weight: 500;
    color: rgb(31, 10, 53);
  }
  
`;

export const SidebarFooter = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border: 1px solid rgba(114, 82, 220, 0.4);
  background: rgba(114, 82, 220, 0.1);
  border-radius: 10px;
  margin-top: auto;
  cursor: pointer;
`;

export const SidebarAvatar = styled.div`
  width: 44px;
  height: 44px;
  
  border-radius: 6px;
  font-family: ${({ theme }) => theme.fonts.secondary};
  background: rgb(114, 82, 220);
  color: rgb(255, 255, 255);
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-radius: 6px;
  margin-right: 12px;
`;

export const SidebarUserInfo = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SidebarUserName = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: rgb(31, 10, 53);
`;

export const SidebarUserEmail = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: rgb(114, 82, 220);
  margin-top: 4px;
`;
