import React, { useContext, createContext, useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  SidebarContainer,
  SidebarHeader,
  SidebarMenu,
  SidebarGroup,
  SidebarItem,
  SidebarFooter,
  SidebarLink,
  SidebarTitle,
  SidebarAvatar,
  SidebarUserInfo,
  SidebarIcon,
  SidebarText,
  SidebarUserName,
  SidebarUserEmail,
} from "./Sidebar.styles";
import moreVerticalIcon from "../../assets/icons/moreVerticalIcon.svg";
import courseImg from "../../assets/icons/courseImg.svg";
import statsImg from "../../assets/icons/statsImg.svg";
import studentIcon from "../../assets/icons/studentIcon.svg";
import homeworkIcon from "../../assets/icons/homeworkIcon.svg";
import refIcon from "../../assets/icons/refIcon.svg";
import paymentIcon from "../../assets/icons/paymentIcon.svg";
import settingsIcon from "../../assets/icons/settingsIcon.svg";
import helpIcon from "../../assets/icons/helpIcon.svg";
import docTempIcon from "../../assets/icons/docTempIcon.svg";
import leadsIcon from "../../assets/icons/leadsIcon.svg";
import Cookies from "js-cookie";
import { Toast } from "primereact/toast";

const SidebarContext = createContext({ expanded: true });

export const SidebarMenuProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [expanded] = useState(true);

  return (
    <SidebarContext.Provider value={{ expanded }}>
      {children}
    </SidebarContext.Provider>
  );
};

const SidebarItemComponent: React.FC<{
  icon: string;
  text: string;
  to: string;
}> = ({ icon, text, to }) => {
  const { expanded } = useContext(SidebarContext);
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <SidebarItem>
      <Link to={to}>
        <SidebarLink isActive={isActive}>
          <SidebarIcon src={icon} alt={text} />
          {expanded && <SidebarText>{text}</SidebarText>}
        </SidebarLink>
      </Link>
    </SidebarItem>
  );
};

const Sidebar: React.FC = () => {
  const [userData, setUserData] = useState<{
    firstName: string;
    lastName: string;
    email: string;
  } | null>(null);

  const navigate = useNavigate();
  const toast = useRef<Toast>(null);


  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const firstName = Cookies.get("first_name");
        const lastName = Cookies.get("last_name");
        const email = Cookies.get("email");

        setUserData({
          firstName: firstName || "Name",
          lastName: lastName || "Lastname",
          email: email || "",
        });
      } catch (error) {
        toast.current?.show({ severity: "error", summary: "Error", detail: "Ошибка загрузки данных пользователя" });
        console.error("Ошибка загрузки данных пользователя:", error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <SidebarContainer>
      <SidebarHeader>LISRENT</SidebarHeader>
      <SidebarMenu>
        <SidebarGroup>
          <SidebarTitle>Курсы и статистика</SidebarTitle>
          <SidebarItemComponent icon={courseImg} text="Мои курсы" to="/courses" />
          <SidebarItemComponent icon={statsImg} text="Статистика" to="/statistics" />
        </SidebarGroup>
        <SidebarGroup>
          <SidebarTitle>Учеба</SidebarTitle>
          <SidebarItemComponent icon={leadsIcon} text="Лиды" to="/leads" />
          <SidebarItemComponent icon={studentIcon} text="Ученики" to="/students" />
          <SidebarItemComponent
            icon={homeworkIcon}
            text="Домашние задания"
            to="/homework"
          />
        </SidebarGroup>
        <SidebarGroup>
          <SidebarTitle>Оплата и акции</SidebarTitle>
          <SidebarItemComponent icon={refIcon} text="Реферальная программа" to="/referals" />
          <SidebarItemComponent icon={paymentIcon} text="Оплата" to="/payment" />
        </SidebarGroup>
        <SidebarGroup>
          <SidebarTitle>Другое</SidebarTitle>
          <SidebarItemComponent icon={docTempIcon} text="Документы" to="/documents" />
          <SidebarItemComponent icon={helpIcon} text="Поддержка" to="/support-requests" />
          <SidebarItemComponent icon={settingsIcon} text="Настройки" to="/settings" />
        </SidebarGroup>
      </SidebarMenu>
      <SidebarFooter onClick={() => navigate("/profile")}>
        <SidebarAvatar>
          {userData
            ? `${userData.firstName[0]}${userData.lastName[0]}`.toUpperCase()
            : "??"}
        </SidebarAvatar>
        <SidebarUserInfo>
          <div>
            <SidebarUserName>
              {userData ? `${userData.firstName} ${userData.lastName}` : "Загрузка..."}
            </SidebarUserName>
            <SidebarUserEmail>
              {userData?.email || "Загрузка..."}
            </SidebarUserEmail>
          </div>
          <img src={moreVerticalIcon} alt="Settings" />
        </SidebarUserInfo>
      </SidebarFooter>
    </SidebarContainer>
  );
};

export default Sidebar;

