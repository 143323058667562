import styled from "styled-components";

export const LeadsPageContainer = styled.div`
  display: flex;
  height: calc(100% - 24px);
  margin: 12px;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  background: white;
  border-radius: 16px;
  box-shadow: 0px 9px 14.5px rgba(0, 0, 0, 0.06),
    0px 0px 6px rgba(31, 10, 53, 0.05);
  padding: 32px 25px;
  box-sizing: border-box;
`;

export const Title = styled.h1`
  font-size: 32px;
  font-weight: 700;
  color: rgb(31, 10, 53);
  margin-bottom: 24px;
`;


export const StatusAndDeleteIconContainer = styled.div`
  display: flex;
  justify-content: center;
`;