import React, { useState, useEffect } from "react";
import { FilterCheckboxListProps, Option } from "./FilterCheckboxList.types";
import {
  FilterContainer,
  OptionItem,
  SaveButton,
  ResetButton,
  ButtonWrapper,
  OptionWrapper,
} from "./FilterCheckboxList.styles";

const FilterCheckboxList: React.FC<FilterCheckboxListProps> = ({
  options,
  onSave,
  onReset,
  selectedOptions,
}) => {
  const [selected, setSelected] = useState<Set<string>>(new Set(selectedOptions || []));

  useEffect(() => {
    setSelected(new Set(selectedOptions || []));
  }, [selectedOptions]);

  const handleToggle = (option: string) => {
    setSelected((prev) => {
      const updated = new Set(prev);
      if (updated.has(option)) {
        updated.delete(option);
      } else {
        updated.add(option);
      }
      return updated;
    });
  };

  const handleSave = () => {
    onSave(Array.from(selected));
  };

  const handleReset = () => {
    setSelected(new Set());
    onReset();
  };

  return (
    <FilterContainer>
      <OptionWrapper>
      {options.map((option: Option) => (
        <OptionItem key={option.value}>
          <input
            type="checkbox"
            id={`checkbox-${option.value}`}
            checked={selected.has(option.value)}
            onChange={() => handleToggle(option.value)}
            style={{ display: "none" }} // Скрываем стандартный чекбокс
          />
          <label htmlFor={`checkbox-${option.value}`}>
            <span>{option.label}</span>
          </label>
        </OptionItem>
      ))}
      </OptionWrapper>
      <ButtonWrapper>
        <SaveButton onClick={handleSave}>Сохранить</SaveButton>
        <ResetButton onClick={handleReset}>Сбросить</ResetButton>
      </ButtonWrapper>
    </FilterContainer>
  );
};

export default FilterCheckboxList;
