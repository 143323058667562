import React from "react";
import { DocumentCardProps } from "./DocumentCard.types";
import { CardContainer, DocumentTitle } from "./DocumentCard.styles";
import StatusBadge from "../StatusBadge/StatusBadge";

const DocumentCard: React.FC<DocumentCardProps> = ({
  title,
  isSubscribed,
  onClick,
}) => {
  return (
    <CardContainer onClick={onClick}>
      <StatusBadge
        text={isSubscribed ? "Подписан" : "Не подписан"}
        variant={isSubscribed ? "green" : "red"}
      />
      <DocumentTitle>{title}</DocumentTitle>
    </CardContainer>
  );
};

export default DocumentCard;
