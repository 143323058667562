import axiosInstance from "../axiosConfig";
import { APIResponse, CreateStudentPayload, CreateStudentResponse, StudentPurchase } from "./studentService.types";

// Получение списка учеников
export const fetchStudents = async (
  page: number = 1,
  pageSize: number = 7,
  ordering: string = ""
): Promise<APIResponse<StudentPurchase>> => {
  const url = `/courses/teacher/purchases/`;
  try {
    const response = await axiosInstance.get(url, {
      params: {
        page,
        page_size: pageSize,
        ordering,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении списка учеников:", error);
    throw error;
  }
};


export const updateNote = async (id: number, note: string): Promise<void> => {
  const url = `courses/teacher/purchases/update_note/${id}/`;
  try {
    await axiosInstance.patch(url, { note });
  } catch (error) {
    console.error("Ошибка при обновлении заметки:", error);
    throw error;
  }
};


export const updateAccess = async (
  id: number,
  courseAccess: boolean
): Promise<{ message: string; course_access: boolean }> => {
  const url = `courses/teacher/purchases/update_access/${id}/`;
  try {
    const response = await axiosInstance.patch(url, { course_access: courseAccess });
    return response.data;
  } catch (error) {
    console.error("Ошибка при обновлении доступа:", error);
    throw error;
  }
};


export const fetchPurchaseById = async (purchaseId: number): Promise<StudentPurchase> => {
  const url = `courses/teacher/purchases/${purchaseId}/`;
  try {
    const response = await axiosInstance.get<StudentPurchase>(url);
    return response.data;
  } catch (error) {
    console.error(`Ошибка при получении данных о покупке с ID ${purchaseId}:`, error);
    throw error;
  }
};

export const createStudent = async (
  payload: CreateStudentPayload
): Promise<CreateStudentResponse> => {
  const url = `courses/teacher/purchases/create_student/`;

  try {
    const response = await axiosInstance.post<CreateStudentResponse>(url, payload);
    return response.data;
  } catch (error) {
    console.error("Ошибка при создании студента:", error);
    throw error;
  }
};