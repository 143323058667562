import styled from "styled-components";

export const NotePageContainer = styled.div`
  display: flex;
  height: calc(100% - 24px);
  margin: 12px;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  padding: 32px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
`;

export const Toolbar = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-bottom: 16px;
`;

export const ToolbarButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
  }

  &:hover {
    opacity: 0.7;
  }
`;

export const TextArea = styled.textarea`
  flex: 1; 
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px; /* Отступ между текстовым полем и кнопкой */
  padding: 16px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  resize: none;
  box-sizing: border-box;
  border: 1px solid rgba(31, 10, 53, 0.1);
  border-radius: 16px;
  box-shadow: 0px 9px 14.5px 0px rgba(0, 0, 0, 0.06),0px 0px 0px 6px rgba(31, 10, 53, 0.05);
  background: rgb(255, 255, 255);
`;

export const SaveButton = styled.button`
  padding: 14px 28px;
  width: 369px;
  border: none;
  border-radius: 12px;
  background: rgb(114, 82, 220);
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background: #603cbc;
  }
`;

export const BreadcrumbsContainer = styled.div`
  display: flex;
  gap: 8px;
  font-size: 14px;
  color: #6c757d;
  margin-bottom: 16px;
`;



export const BreadcrumbItem = styled.span`
  color: rgba(31, 10, 53, 0.5);
`;
