export const theme = {
    colors: {
    },
    fonts: {
      primary: '"Inter", sans-serif',
      secondary: '"Manrope", sans-serif',
      display: '"SF Pro Display", sans-serif',
      mont: '"Mont", sans-serif',
    },
  };
  