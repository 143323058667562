import styled from "styled-components";

export const ProfilePageContainer = styled.div`
  display: flex;
  height: calc(100% - 24px);
  margin: 12px;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  margin-left: 0;
  background: white;
  border-radius: 16px;
  box-shadow: 0px 9px 14.5px rgba(0, 0, 0, 0.06),
    0px 0px 6px rgba(31, 10, 53, 0.05);
  padding: 32px 25px;
  box-sizing: border-box;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

export const Title = styled.h1`
  font-size: 32px;
  font-weight: 700;
  color: rgb(31, 10, 53);
`;

export const TemplateList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;

export const TemplateItem = styled.div``;

export const TemplateButton = styled.button`
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  transition: background 0.2s;
  cursor: pointer;

  box-sizing: border-box;
  border: 1px solid rgba(31, 10, 53, 0.1);
  border-radius: 16px;

  box-shadow: 0px 9px 14.5px 0px rgba(0, 0, 0, 0.06);
  background: rgb(255, 255, 255);
  padding: 24px 38px 24px 38px;
  cursor: pointer;

  color: rgb(31, 10, 53);
  font-size: 24px;
  font-weight: 500;

  &:hover {
    &:hover {
      transform: translateY(-3px);
      transition: transform 0.2s ease;
    }
  }
`;
