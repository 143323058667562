import styled from 'styled-components';

export const CalendarContainer = styled.div`
  .react-datepicker {
    border: none;
    border-radius: 12px; /* Было 16px */
    background-color: white;
    font-family: 'Arial', sans-serif;
    font-size: 14px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);

    /* Шапка */
    .react-datepicker__header {
      background-color: white;
      color: #333;
      border-bottom: none;
      border-top-left-radius: 12px;  /* Было 16px */
      border-top-right-radius: 12px; /* Было 16px */
      text-align: center;
    }

    .react-datepicker__current-month {
      font-weight: bold;
      font-size: 16px;
      color: #333;
    }

    .react-datepicker__month-container {
      padding: 16px;
    }

    .react-datepicker__day-name {
      margin-top: 10px;
    }

    /* Базовые стили для различных "выделенных" состояний —
       чтобы не дублировать background и color */
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__day--range-start,
    .react-datepicker__day--range-end,
    .react-datepicker__day--selecting-range-end,
    .react-datepicker__day--selected,
    .react-datepicker__day--today {
      background: rgba(114, 82, 220, 0.19);
      color: rgb(114, 82, 220) !important;
      margin-top: 10px;
    }

    /* Наведение */
    .react-datepicker__day:hover {
      background: rgba(114, 82, 220, 0.19);
      color: rgb(114, 82, 220) !important;
      border-radius: 12px;
    }

    /* Для непрерывного фона — убираем радиусы у дней внутри диапазона */
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range {
      border-radius: 0 !important;
    }

    /* Скругление у начала / конца диапазона */
    .react-datepicker__day--range-start {
      border-radius: 12px 0 0 12px !important;
    }
    .react-datepicker__day--range-end,
    .react-datepicker__day--selecting-range-end {
      border-radius: 0 12px 12px 0 !important;
    }

    /* Выбранный день (если не диапазон, а одиночная дата) */
    .react-datepicker__day--selected {
      /* фон/цвет уже есть выше, нужен только радиус: */
      border-radius: 12px 0 0 12px !important;
    }

    /* Первый и последний день в selecting-range */
    .react-datepicker__day--in-selecting-range:first-child {
      border-radius: 12px 0 0 12px !important; /* Было 16px */
    }
    .react-datepicker__day--in-selecting-range:last-child {
      border-radius: 0 12px 12px 0 !important; /* Было 16px */
    }

    /* "Сегодня" */
    .react-datepicker__day--today {
      border-radius: 12px;
    }

    /* Общие стили для day-name / day / time-name */
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      color: rgb(225, 225, 225);
      margin-right: 0;
      margin-left: 0;
      font-weight: 700;
      font-size: 14px;

      &:last-child {
        padding-right: 0;
      }
      &:first-child {
        padding-left: 0;
      }
    }

    /* Уточнение цвета для названий дней */
    .react-datepicker__day-name {
      color: rgb(165, 165, 165);
    }

    /* Стрелочки переключения */
    .react-datepicker__navigation {
      color: #000000;
      position: absolute;
      top: 12%;
      transform: translateY(-50%);
      width: 40px;
      height: 40px;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      z-index: 1;
    }

  }
`;
