import styled from "styled-components";

export const ProfilePageContainer = styled.div`
  display: flex;
  height: calc(100% - 24px);
  margin: 12px;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  margin-left: 0;
  background: white;
  border-radius: 16px;
  box-shadow: 0px 9px 14.5px rgba(0, 0, 0, 0.06),
    0px 0px 6px rgba(31, 10, 53, 0.05);
  padding: 32px 25px;
  box-sizing: border-box;
  width: calc(100% - 260px);
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

export const Title = styled.h1`
  font-size: 32px;
  font-weight: 700;
  color: rgb(31, 10, 53);
`;

export const ButtonWrapper = styled.div``;


export const TariffCardsContainer = styled.div`
  display: flex;
  gap: 40px;
  height: calc(100% - 100px);
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow-x: auto;

  /* Стилизация скроллбара для Webkit-браузеров */
  &::-webkit-scrollbar {
    height: 8px; /* Высота скроллбара */
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(114, 82, 220); /* Цвет скроллбара */
    border-radius: 4px; /* Закругленные углы */
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(114, 82, 220, 0.1); /* Фон трека скроллбара */
    border-radius: 4px;
  }

  /* Для Firefox */
  scrollbar-color: rgb(114, 82, 220) rgba(114, 82, 220, 0.1);
  scrollbar-width: thin; /* Узкий скроллбар */
`;



