import styled from "styled-components";

export const CardContainer = styled.div`
  width: 481px;
  height: 193px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding: 24px 38px 24px 38px;

  box-sizing: border-box;
  border: 1px solid rgba(31, 10, 53, 0.1);
  border-radius: 16px;

  box-shadow: 0px 9px 14.5px 0px rgba(0, 0, 0, 0.06);
  background: rgb(255, 255, 255);

`;

export const DocumentTitle = styled.h4`
  align-self: start;
  color: rgb(31, 10, 53);
  font-size: 24px;
  font-weight: 700;
`;
