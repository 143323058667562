import React from "react";
import {
  PaginatorContainer,
  PageContainer,
  NavButtonsContainer,
  PageButton,
  ActivePageButton,
  NavButton,
} from "./Paginator.styles";
import { PaginatorProps } from "./Paginator.types";
import Dropdown from "../Dropdowns/Dropdown";
import dropDownArrowIcon from "../../assets/icons/dropDownArrowIcon.svg";


const Paginator: React.FC<PaginatorProps> = ({
  currentPage,
  totalPages,
  onPageChange,
  pageSize,
  onPageSizeChange,
  rowAmountOptions
}) => {
  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      onPageChange(newPage);
    }
  };

  const renderPages = () => {
    const pages = [];

    // Первая страница
    pages.push(
      currentPage === 1 ? (
        <ActivePageButton key={1}>{1}</ActivePageButton>
      ) : (
        <PageButton key={1} onClick={() => handlePageChange(1)}>
          1
        </PageButton>
      )
    );

    // Три точки перед центральным блоком, если текущая страница далеко от начала
    if (currentPage > 4) {
      pages.push(<span key="start-ellipsis">...</span>);
    }

    // Центральный блок: 3 страницы вокруг текущей
    const startPage = Math.max(2, currentPage - 1);
    const endPage = Math.min(totalPages - 1, currentPage + 1);
    for (let page = startPage; page <= endPage; page++) {
      pages.push(
        currentPage === page ? (
          <ActivePageButton key={page}>{page}</ActivePageButton>
        ) : (
          <PageButton key={page} onClick={() => handlePageChange(page)}>
            {page}
          </PageButton>
        )
      );
    }

    // Три точки перед последней страницей, если текущая страница далеко от конца
    if (currentPage < totalPages - 3) {
      pages.push(<span key="end-ellipsis">...</span>);
    }

    // Последняя страница
    if (totalPages > 1) {
      pages.push(
        currentPage === totalPages ? (
          <ActivePageButton key={totalPages}>{totalPages}</ActivePageButton>
        ) : (
          <PageButton
            key={totalPages}
            onClick={() => handlePageChange(totalPages)}
          >
            {totalPages}
          </PageButton>
        )
      );
    }

    return pages;
  };

  return (
    <PaginatorContainer>
      <Dropdown
        options={rowAmountOptions}
        selectedValue={pageSize}
        onChange={(value) => onPageSizeChange(value)}
      />


      <PageContainer>{renderPages()}</PageContainer>

      <NavButtonsContainer>
        {currentPage > 1 && (
          <NavButton direction="left" onClick={() => handlePageChange(currentPage - 1)}>
            Назад
          </NavButton>
        )}
        {currentPage < totalPages && (
          <NavButton direction="right" onClick={() => handlePageChange(currentPage + 1)}>
            Вперед
          </NavButton>
        )}
      </NavButtonsContainer>
    </PaginatorContainer>
  );
};

export default Paginator;



