import React, { useEffect, useRef, useState } from "react";
import {
  ContentWrapper,
  HeaderContainer,
  Title,
  ButtonWrapper,
  ProfilePageContainer,
  TariffCardsContainer,
} from "./ProfilePage.styles";
import Button from "../../components/Buttons/Button";
import Sidebar from "../../components/Sidebar";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { fetchTariffs, createPayment } from "../../services/subscriptionService/subscriptionService";
import TariffCard from "../../components/TariffCard/TariffCard";
import { Tariff } from "../../pages/SubscriptionPage/SubscriptionPage.types";
import { Toast } from "primereact/toast";
import ConfirmModal from "../../components/Modals/ConfirmModal/ConfirmModal";

const ProfilePage: React.FC = () => {
  const navigate = useNavigate();
  const [tariffs, setTariffs] = useState<Tariff[]>([]);
  const [loading, setLoading] = useState(true);
  const toastRef = useRef<Toast>(null); // Реф для работы с тостами
  const [isFinalConfirmModalOpen, setIsFinalConfirmModalOpen] = useState(false);
  const [selectedTariffId, setSelectedTariffId] = useState<number | null>(null);

  const handleLogout = () => {
    Cookies.remove("access_token");
    Cookies.remove("refresh_token");
    Cookies.remove("user_id");
    localStorage.clear();
    navigate("/login");
    toastRef.current?.show({
      severity: "success",
      summary: "Успех",
      detail: "Вы успешно вышли из системы.",
      life: 3000,
    });
  };

  const handleTariffChange = (tariffId: number) => {
    setSelectedTariffId(tariffId);
    setIsFinalConfirmModalOpen(true);
  };

  const confirmTariffChange = async () => {
    setIsFinalConfirmModalOpen(false);

    if (selectedTariffId) {
      try {
        const paymentUrl = await createPayment(selectedTariffId);
        window.location.href = paymentUrl; 
      } catch (error) {
        toastRef.current?.show({
          severity: "error",
          summary: "Ошибка",
          detail: "Не удалось создать платеж. Попробуйте позже.",
          life: 5000,
        });
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchTariffs();
        setTariffs(data);
        toastRef.current?.show({
          severity: "info",
          summary: "Данные загружены",
          detail: "Тарифы успешно загружены.",
          life: 3000,
        });
      } catch (error) {
        console.error("Ошибка загрузки тарифов:", error);
        toastRef.current?.show({
          severity: "error",
          summary: "Ошибка",
          detail: "Не удалось загрузить тарифы.",
          life: 5000,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <p>Загрузка тарифов...</p>;
  }

  return (
    <ProfilePageContainer>
      <Toast ref={toastRef} />
      <Sidebar />
      <ContentWrapper>
        <HeaderContainer>
          <Title>Личный кабинет</Title>
          <ButtonWrapper>
            <Button
              variant="red"
              width="120px"
              height="50px"
              onClick={handleLogout}
            >
              Выход
            </Button>
          </ButtonWrapper>
        </HeaderContainer>
        <TariffCardsContainer>
          {tariffs.map((tariff) => (
            <TariffCard
              key={tariff.id}
              title={tariff.name}
              description={{
                active: tariff.description,
                nonActive: tariff.non_active_description,
              }}
              price={tariff.price}
              isActive={tariff.is_active}
              expirationDate={tariff.expiration_date}
              duration={tariff.duration_days}
              tariffId={tariff.id}
              onChangeTariff={() => handleTariffChange(tariff.id)}
            />
          ))}
        </TariffCardsContainer>

        <ConfirmModal
          isOpen={isFinalConfirmModalOpen}
          title="Подтверждение"
          message="При смене тарифа подписка начинается заново"
          confirmButtonText="Обновить"
          onConfirm={confirmTariffChange}
          onCancel={() => setIsFinalConfirmModalOpen(false)}
        />
      </ContentWrapper>
    </ProfilePageContainer>
  );
};

export default ProfilePage;
