import React, { useState, useEffect } from "react";
import {
  SettingsFormContainer,
  FieldWrapper,
  Input,
  SubmitButton,
  ErrorMessage,
  Divider,
  ModalHeader,
  ModalBody,
  ButtonsContainer,
  DeleteButton,
  Manual,
} from "./SettingsForm.styles";
import { SettingsFormProps } from "./SettingsForm.types";

const SettingsForm: React.FC<SettingsFormProps> = ({
  title,
  fields,
  onSubmit,
  onRestart,
  onDelete,
  onChange,
  submitButtonText = "Сохранить",
  externalErrors = {},
  additionalButtons = false,
  isButtonsDisabled = false,
}) => {
  const [formData, setFormData] = useState<Record<string, string>>({});
  const [errors, setErrors] = useState<Record<string, string | null>>({});

  useEffect(() => {
    setErrors((prevErrors) => {
      if (JSON.stringify(prevErrors) !== JSON.stringify(externalErrors)) {
        return {
          ...prevErrors,
          ...externalErrors,
        };
      }
      return prevErrors;
    });
  }, [externalErrors]);

  useEffect(() => {
    const initialFormData = fields.reduce((acc, field) => {
      acc[field.name] = field.value || "";
      return acc;
    }, {} as Record<string, string>);
    setFormData(initialFormData);
  }, [fields]);

  const handleChange = (name: string, value: string) => {
    setFormData({ ...formData, [name]: value });
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }

    if (onChange) {
      onChange(name, value);
    }
  };

  const handleSubmit = () => {
    const newErrors = fields.reduce<Record<string, string | null>>((acc, field) => {
      if (field.required && !formData[field.name]?.trim()) {
        acc[field.name] = "Это поле обязательно";
      }
      return acc;
    }, {});

    setErrors(newErrors);

    if (Object.values(newErrors).every((err) => !err)) {
      onSubmit(formData);
    }
  };

  const isBotTokenFilled = !!formData["botToken"]?.trim();

  return (
    <SettingsFormContainer>
      <ModalHeader>
        <h2>{title}</h2>
      </ModalHeader>
      <Divider />
      <ModalBody>
        {fields.map((field) => (
          <FieldWrapper key={field.name}>
            {errors[field.name] && <ErrorMessage>{errors[field.name]}</ErrorMessage>}
            <Input
              type={field.type}
              placeholder={field.placeholder}
              value={formData[field.name]}
              onChange={(e) => handleChange(field.name, e.target.value)}
            />
          </FieldWrapper>
        ))}
      </ModalBody>
      {additionalButtons && (
        <>
      <Manual>Смотреть инструкцию</Manual>
      <ButtonsContainer> 
          <SubmitButton onClick={handleSubmit} isDisabled={!isBotTokenFilled}>
              {submitButtonText}
          </SubmitButton>
          <SubmitButton onClick={onRestart} isDisabled={isButtonsDisabled}>Перезапустить</SubmitButton>
          <DeleteButton onClick={onDelete} isDisabled={isButtonsDisabled}>Удалить</DeleteButton>
      </ButtonsContainer>
        </>
      )}
      {!additionalButtons && (
        <SubmitButton onClick={handleSubmit}>{submitButtonText}</SubmitButton>
      )}


    </SettingsFormContainer>
  );
};

export default SettingsForm;
