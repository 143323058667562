import styled from "styled-components";

export const SettingsPageContainer = styled.div`
  display: flex;
  height: calc(100% - 24px);
  margin: 12px;
`;

export const PageContent = styled.div`
  flex: 1;
  margin-left: 0;
  background: white;
  border-radius: 16px;
  box-shadow: 0px 9px 14.5px rgba(0, 0, 0, 0.06),
    0px 0px 6px rgba(31, 10, 53, 0.05);
  padding: 32px 25px;
  box-sizing: border-box;
`;

export const SectionHeader = styled.h1`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #1f0a35;
`;

export const FormsContainer = styled.div`
  height: 450px;
  display: flex;
  gap: 20px;
  align-items: start;
  justify-content: center;
  margin-top: 180px;
`;
