import React, { useEffect, useState, useRef } from "react";
import Table from "../../components/Table";
import Sidebar from "../../components/Sidebar";
import { Toast } from "primereact/toast";
import { ContentWrapper, LeadsPageContainer, StatusAndDeleteIconContainer, Title } from "./LeadPage.styles";
import { fetchUserId, fetchCourses, getLeads, selectCourseForLead, deleteLead, fetchLeadRoles } from "../../services/leadService/leadService";
import { Course, LeadData } from "../../services/leadService/leadService.types";
import DropdownInCell from "../../components/Dropdowns/DropdownInCell/DropdownInCell";
import delIcon from "../../assets/icons/delIcon.svg";
import checkedIcon from "../../assets/icons/checkedIcon.svg";
import IconButton from "../../components/Buttons/IconButton";
import DeletedRow from "../../components/DeletedRow";
import { ConfirmModal } from "../../components/Modals";
import { useNavigate } from "react-router-dom";
import { TableProps } from "../../components/Table/Table.types";

const LeadsPage: React.FC = () => {
  const [leads, setLeads] = useState<LeadData[]>([]);
  const [courses, setCourses] = useState<Course[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(7);
  const [orderBy, setOrderBy] = useState<string>("");

  const [pendingDelete, setPendingDelete] = useState<number | null>(null);
  const [deleteLeadId, setDeleteLeadId] = useState<number | null>(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const deleteTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const toast = React.useRef<Toast>(null);

  const [filterStartDate, setFilterStartDate] = useState<Date | null>(new Date());
  const [filterEndDate, setFilterEndDate] = useState<Date | null>(null);
  const [activeFilter, setActiveFilter] = useState<string | null>(null);

  const [roles, setRoles] = useState<{ label: string; value: string }[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);

  const [filters, setFilters] = useState<Record<string, string[]>>({});

  const navigate = useNavigate();

  useEffect(() => {
    loadLeads();
    loadCourses();
  }, [filters, currentPage, pageSize, orderBy]);

  useEffect(() => {
    if (filterStartDate && filterEndDate) {
      loadLeads();
    }
  }, [filterStartDate, filterEndDate]);

  const loadLeads = async () => {
    setLoading(true);
    setError(null);
    console.log(filters);
    try {
      const data = await getLeads(
        currentPage,
        pageSize,
        orderBy,
        filterStartDate ? filterStartDate.toISOString().split("T")[0] : undefined,
        filterEndDate ? filterEndDate.toISOString().split("T")[0] : undefined,
        filters["role"] && filters["role"].length > 0
        ? filters["role"].join(",")
        : undefined // Преобразуем массив ролей в строку
      );
  
      setLeads(data.results);
      setTotalPages(Math.ceil(data.count / pageSize));
    } catch (err) {
      console.error("Ошибка при загрузке лидов:", err);
      toast.current?.show({
        severity: "error",
        summary: "Ошибка",
        detail: "Не удалось загрузить лидов",
        life: 3000,
      });
      setError("Не удалось загрузить лидов.");
    } finally {
      setLoading(false);
    }
  };
  


  const handleFilterSave = (key: string, selectedValues: string[]) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [key]: selectedValues,
    }));
    setCurrentPage(1);
    setActiveFilter(null);
  };
  
  const handleFilterReset = (key: string) => {
    setFilters(prevFilters => {
      const updatedFilters = { ...prevFilters };
      delete updatedFilters[key];
      return updatedFilters;
    });
    setCurrentPage(1);
    setActiveFilter(null);
  };
  


  const loadCourses = async () => {
    try {
      const userId = await fetchUserId();
      const coursesData = await fetchCourses({ userId });

      const normalizedCourses = coursesData.map((course) => ({
        id: Number(course.id),
        title: course.title,
      }));

      setCourses(normalizedCourses);
    } catch (err) {
      console.error("Ошибка при загрузке курсов:", err);
      toast.current?.show({
        severity: "error",
        summary: "Ошибка",
        detail: "Не удалось загрузить курсы",
        life: 3000,
      });
    }
  };

  const handleCourseChange = async (leadId: number, selectedCourseId: string | number) => {
    const previousLeads = [...leads];

    setLeads((prevLeads) =>
      prevLeads.map((lead) =>
        lead.id === leadId ? { ...lead, selected_course: selectedCourseId } : lead
      )
    );

    try {
      await selectCourseForLead(leadId, Number(selectedCourseId));
      toast.current?.show({
        severity: "success",
        summary: "Успешно",
        detail: `Курс успешно изменён для лида ${leadId}`,
        life: 3000,
      });
    } catch (error) {
      console.error("Ошибка при обновлении курса для лида:", error);
      setLeads(previousLeads);

      toast.current?.show({
        severity: "error",
        summary: "Ошибка",
        detail: `Не удалось изменить курс для лида ${leadId}`,
        life: 3000,
      });
    }
  };

  const handleDeleteLead = (leadId: number) => {
    setPendingDelete(leadId);

    deleteTimeoutRef.current = setTimeout(async () => {
      try {
        await deleteLead(leadId);
        setPendingDelete(null);
        loadLeads();
      } catch (error) {
        console.error("Ошибка при удалении лида:", error);
        toast.current?.show({
          severity: "error",
          summary: "Ошибка",
          detail: `Ошибка удаления лида ${leadId}`,
          life: 3000,
        });
      }
    }, 5000); // Удаление через 5 секунд
  };

  const confirmDelete = (leadId: number) => {
    setDeleteLeadId(leadId); // Сохраняем ID лида для удаления
    setIsConfirmModalOpen(true); // Открываем модальное окно
  };

  const handleConfirm = () => {
    if (deleteLeadId !== null) {
      handleDeleteLead(deleteLeadId);
    }
    setIsConfirmModalOpen(false); // Закрываем модальное окно
    setDeleteLeadId(null); // Сбрасываем ID лида
  };

  const handleCancel = () => {
    setIsConfirmModalOpen(false); // Закрываем модальное окно
    setDeleteLeadId(null); // Сбрасываем ID лида
  };

  const restoreLead = (leadId: number) => {
    if (deleteTimeoutRef.current) {
      clearTimeout(deleteTimeoutRef.current);
      deleteTimeoutRef.current = null;
    }
    setPendingDelete(null);
    toast.current?.show({
      severity: "info",
      summary: "Восстановление",
      detail: `Удаление лида отменено`,
      life: 3000,
    });
  };

  const handlePageChange = (page: number) => setCurrentPage(page);

  const handlePageSizeChange = (size: number) => {
    setPageSize(size);
    setCurrentPage(1);
  };

  const handleSort = (field: string) => {
    if (orderBy === field) setOrderBy(`-${field}`);
    else if (orderBy === `-${field}`) setOrderBy("");
    else setOrderBy(field);
  };

  const handleCalendarChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setFilterStartDate(start);
    setFilterEndDate(end);

    if (start && end) {
      setActiveFilter(null);
    }
  };
  const handleActiveFilterChange = (filter: string | null) => {
    setActiveFilter(filter);
  };

  useEffect(() => {
    loadRoles();
  }, []);

  const loadRoles = async () => {
    try {
      const rolesData = await fetchLeadRoles();
      const normalizedRoles = rolesData.map((role) => ({
        label: role.name,
        value: String(role.id),
      }));
      setRoles(normalizedRoles); // Сохраняем роли
    } catch (err) {
      console.error("Ошибка при загрузке ролей:", err);
      toast.current?.show({
        severity: "error",
        summary: "Ошибка",
        detail: "Не удалось загрузить роли",
        life: 3000,
      });
    }
  };
  const headers: TableProps["headers"] = [
    { label: "Фамилия", key: "surname", fieldName: "surname" },
    { label: "Имя", key: "name", fieldName: "name" },
    { label: "Телеграм", key: "telegram", fieldName: "telegram" },
    {
      label: "Дата заявки",
      key: "created",
      fieldName: "created",
      isFilter: true,
      filterType: "calendar",
    },
    { label: "Номер телефона", key: "phone", fieldName: "phone" },
    {
      label: "Роль",
      key: "role",
      fieldName: "role",
      isFilter: true,
      filterType: "list",
      listOptions: roles
    },
    { label: "Курс", key: "course", fieldName: "course__title" },
    { label: "Заметки", key: "notes", fieldName: "notes", sortable: false },
    { label: "Курс/группа", key: "selected_course", fieldName: "selected_course__title" },
    { label: "", key: "statusAndDelete", fieldName: "statusAndDelete", sortable: false },
  ];

  const rows = leads.map((lead) => {
    if (lead.id === pendingDelete) {
      return {
        name: (
          <DeletedRow
            id={lead.id}
            onRestore={restoreLead}
            colSpan={9}
            message={`Лид будет удалён через 5 секунд.`}
            targetText="Отменить"
          />
        ),
      };
    }

    return {
      surname: lead.surname,
      name: lead.name,
      telegram: lead.telegram ? (
        <a href={`https://t.me/${lead.telegram}`} target="_blank" rel="noopener noreferrer">
          @{lead.telegram}
        </a>
      ) : (
        "-"
      ),
      created: lead.created ? new Date(lead.created).toLocaleDateString() : "-",
      phone: lead.phone,
      role: lead.role_name || "-",
      course: lead.course_name || "-",
      notes: (
        <span
          style={{ color: "rgb(114, 82, 220)", cursor: "pointer" }}
          onClick={() => navigate(`/leads/${lead.id}/note`)}
        >
          Редактировать
        </span>
      ),
      selected_course: lead.selected_course ? (
        <>{courses.find((course) => String(course.id) === String(lead.selected_course))?.title || "Курс не найден"}</>
      ) : (
        <DropdownInCell
          options={courses.map((course) => ({
            label: course.title,
            value: String(course.id),
          }))}
          value={String(lead.selected_course)}
          onChange={(selectedValue) => {
            if (lead.id !== undefined) {
              handleCourseChange(lead.id, selectedValue);
            } else {
              console.error("ID лида отсутствует");
            }
          }}
        />
      ),
      statusAndDelete: (
        <StatusAndDeleteIconContainer>
          {lead.selected_course && (
            <IconButton icon={checkedIcon} altText="Одобрить" />
          )}
          <IconButton
            icon={delIcon}
            altText="Удалить"
            onClick={() => {
              if (lead.id !== undefined) {
                confirmDelete(lead.id);
              } else {
                console.error("ID лида отсутствует");
              }
            }}
          />
        </StatusAndDeleteIconContainer>
      ),
    };
  });

  console.log(filters);
  return (
    <LeadsPageContainer>
      <Toast ref={toast} />
      <Sidebar />
      <ContentWrapper>
        <Title>Список лидов</Title>
        <Table
          headers={headers}
          rows={rows}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          pageSize={pageSize}
          onPageSizeChange={handlePageSizeChange}
          onSort={handleSort}
          loading={loading}
          rowAmountOptions={[
            { label: "7 лидов", value: 7 },
            { label: "15 лидов", value: 15 },
            { label: "20 лидов", value: 20 },
          ]}
          specialRowKey="name"
          onCalendarChange={handleCalendarChange}
          activeFilter={activeFilter}
          filterStartDate={filterStartDate || undefined}
          filterEndDate={filterEndDate || undefined}
          handleActiveFilterChange={handleActiveFilterChange}
          appliedFilters={filters}
          onFilterSave={handleFilterSave} // Универсальный обработчик сохранения
          onFilterReset={handleFilterReset} // Универсальный обработчик сброса
        />

      </ContentWrapper>
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        title="Подтвердите удаление"
        message="Вы уверены, что хотите удалить лид?"
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    </LeadsPageContainer>
  );
};

export default LeadsPage;
